import { MainAppRepo } from '~/domain/repositories/MainAppRepo'

export default {
  async fetchPricingData({ commit }, { context }): Promise<any> {
    try {
      const mainAppRepo = new MainAppRepo(context)
      const planFeatures = await mainAppRepo.fetchData('get-plan-features')
      const prodConfig = await mainAppRepo.fetchData('get-prod-config')
      // TODO: get productIds from mainApp

      commit('updatePlanFeatures', planFeatures)
      commit('updateProdConfig', prodConfig)
    } catch (e) {
      console.error(e)
      return null
    }
  },
}
