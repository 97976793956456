/*
this is where we will eventually hold the data for all of our posts
*/
export const state = () => ({
  data: [],
  hostname: '',
  fetchUserComplete: false,
  userIsFetching: false,
  renewalTimer: null
})
/*
this will update the state with the posts
*/
export const mutations = {
  setRenewalTimer: (state, timer) => {
    state.renewalTimer = timer;
  },

  updateData: (state, posts) => {
    state.data = posts
  },
  setHostname: (state, hostname) => {
    state.hostname = hostname
  },
  setFetchUserComplete: (state, isComplete) => {
    state.fetchUserComplete = isComplete
  },
  setUserIsFetching: (state, isFetching) => {
    state.userIsFetching = isFetching
  }
}

export const getters = {
  userIsAuthenticated: (state, getters) => {
    return state.auth && state.auth.user && state.auth.user.username
      ? state.auth.loggedIn
      : false
  },

  authenticatedUser: (state) => {
    return state.auth && state.auth.user && state.auth.user.username
      ? state.auth.user
      : null
  },

  hostname: (state) => state.hostname,

  fetchUserComplete: (state) => state.fetchUserComplete,

  userIsFetching: (state) => state.userIsFetching,
}

/*

actions is where we will make an API call that gathers the posts,
and then commits the mutation to update it
*/
export const actions = {
  async startTokenRenewalTimer({ dispatch }) {
    const renewalInterval = 3600 * 1000 // 1 hour
    const revewTimer = setInterval(() => {
      dispatch('silentTokenRenewal');
    }, renewalInterval)

    return revewTimer;
  },

  async silentTokenRenewal({ commit, dispatch }) {
    console.log('silentTokenRenewal')
    try {
      await this.$auth.refreshTokens();
      // commit('setFetchUserComplete', true);
    } catch (err) {
      console.error(err);
      dispatch('logout');
    }
  },

  nuxtServerInit(store, context) {
    if (!store.state.hostname) {
      store.commit(
        'setHostname',
        [context?.req?.protocol, context?.req?.headers?.host].join('://')
      )
    }
  },
}
