
export default {
  methods: {
    wrapperCss() {
      return `
        background-size: cover;
        background-position: center;
        width: 100vw;
      `
    },
  },
}
