import { DesignersStoreState } from '@interfaces/DesignersTypes'
import { UserType } from '~/domain/interfaces'

export default {
  setIsFetching(state: DesignersStoreState, value: boolean) {
    state.fetching = value
  },
  updateFeaturedList(state: DesignersStoreState, data) {
    state.featured = data
  },

  updateDesignersList(state: DesignersStoreState, data) {
    state.designersList = data
  },

  updateDesignersTotal(state: DesignersStoreState, data: number) {
    state.total = data
  },

  updateForHireList(state: DesignersStoreState, data) {
    state.forHire = data
  },

  setIsFollowedInList(state: DesignersStoreState, data: {username: string, isFollowed: boolean}) {
    state.designersList?.forEach(designer => {
      if(designer.username === data.username) {
        designer.isFollowed = data.isFollowed
        if(designer.followerCount)
          designer.followerCount = data.isFollowed ? designer.followerCount + 1 : designer.followerCount - 1
      }
    })
  },

  setIsFollowed(state: DesignersStoreState, data: {designer: UserType, isFollowed: boolean}) {
    data.designer.isFollowed = data.isFollowed
  },

  setFeaturedLoaded(state: DesignersStoreState, isLoaded: boolean) {
    state.featuredLoaded = isLoaded
  }
}
