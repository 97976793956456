import {
  CommunityStoreState,
  WpDataType,
  UserLoginInfoType,
  UserType,
  Diagram,
  CommunitySlugs,
} from "@interfaces/index";
import { ActivityFeedItem } from "@interfaces/index";

const communityState: CommunityStoreState = {
  wpData: {
    machinationsAppUrl: "",
    // Community
    designersOfTheMonth: [] as UserType[],
    diagramTabs: [] as Diagram[][],
    designerTabs: [] as UserType[][],

    // Profile
    user: null as UserType | null,
    userDiagrams: [] as Diagram[],
    userLikedDiagrams: [] as Diagram[],

    // Diagram
    diagram: {} as Diagram,
    relatedDiagrams: [] as Diagram[],
  } as WpDataType,

  wpAtts: [], // eslint-disable-line

  trackSegmentEvent: typeof Function,

  // Authenticated user
  user: {} as UserLoginInfoType,

  userLoginSet: false,

  spotlights: [],

  activeSlug: CommunitySlugs.NONE,

  activityFeed: {
    idsViewed: [] as number[],
    list: [] as ActivityFeedItem[],
    lastPage: 0,
    recordsPerPage: 10,
    hasMore: true,
    hasNew: false,
  },
};

export default () => communityState;
