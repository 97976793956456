import { SegmentEventParams } from '~/domain/repositories/SegmentRepo'
import { CommunityRepo } from '@repositories/CommunityRepo'
import { Context } from '@nuxt/types'
import {
  COMMUNITY_FILTERS,
  DiagramStoreActions,
  DesignersStoreActions,
  Tag,
} from '~/domain/interfaces'
import { CARDS_PER_PAGE } from '~/domain/constants'
import {
  wordCapitalDesigners,
  wordCapitalDiagrams,
  searchSomething,
} from '~/locales/en'
import {
  isSeeAllFilter,
  isDesignersFilter,
  isDiagramsFilter,
} from '~/domain/helpers'

export default {
  // eslint-disable-next-line
  trackSegmentEvent({ state }: any, data: SegmentEventParams) {
    if (typeof state.trackSegmentEvent === 'function') {
      state.trackSegmentEvent(
        null,
        data.name,
        data.category,
        data.action,
        data.label,
        data.value || '',
        data.misc
      )
    }
  },

  async fetchActivityFeedPage({ commit, dispatch }, context: Context) {
    await dispatch(
      'wp/fetchPageBySlug',
      {
        context,
        slug: 'activity-feed',
      },
      { root: true }
    )
    commit('activityFeedResetPage')
  },

  async fetchActivityFeed(
    { commit, state },
    params: { context: Context; user: any }
  ) {
    const repoCommunity = new CommunityRepo(params.context)

    //get current user if logged in for username

    const activityFeedContent = await repoCommunity.fetchActivityFeed({
      count: state.activityFeed.recordsPerPage,
      page: state.activityFeed.lastPage + 1,
      username: params.user ? params.user.username : '',
    })

    commit('activityFeedAddList', activityFeedContent.list)
    commit('activityFeedIncrementPage', activityFeedContent)

    return activityFeedContent
  },

  async markActivityAsViewed(
    { commit, state },
    params: { context: Context; id: number }
  ) {
    //mark them as viewd locally
    commit('setActivitiesAsViewd', params.id)
    commit('checkIfHasNew')
  },

  async sendActivityIds(
    { commit, state },
    params: { context: Context; username: string }
  ) {
    //send gethered ids to mark as view in backend
    const repoCommunity = new CommunityRepo(params.context)
    const activityResponse = await repoCommunity.markActivityFeedAsViewed({
      ids: state.activityFeed.idsViewed,
      username: params.username,
    })
    commit('resetIdsViewd')
  },

  async getSeeAllResults(
    { dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: {
        filter: string
        searchTerm: string
        tagId: string
        pageNo: number
        source: string
      }
    }
  ): Promise<any> {
    let currentResult = {} as any
    if (params?.filter?.length > 0) {
      switch (params.filter) {
        case COMMUNITY_FILTERS.DESIGNERS:
          currentResult = await dispatch('getDesigners', {
            context,
            params,
          })
          break
        case COMMUNITY_FILTERS.DIAGRAMS:
          currentResult = await dispatch('getDiagrams', {
            context,
            params,
          })
          break
        case COMMUNITY_FILTERS.NEW_TEMPLATES:
          currentResult = await dispatch('getDiagrams', {
            context,
            params: { ...params, source: 'templates' },
          })
          break
        case COMMUNITY_FILTERS.TAGS:
          currentResult = await dispatch('getDiagramsByTags', {
            context,
            params,
          })
          break
        case COMMUNITY_FILTERS.DESIGNERS_FOR_HIRE:
          currentResult = await dispatch('getDesignersForHire', context)
          break
        case COMMUNITY_FILTERS.DESIGNERS_OF_THE_MONTH:
          currentResult = await dispatch('getDesignersOfTheMonth', context)
          break
        default:
          currentResult = await dispatch('getDiagrams', {
            context,
            params,
          })
          break
      }

      if (isDesignersFilter(params.filter)) {
        return {
          ...currentResult,
          searchPlaceholder: searchSomething.replace('{target}', 'Designers'),
          title: wordCapitalDesigners,
          class: params.filter,
          sortingCategories: {
            selectedItem: 'Recently Active',
            items: ['Recently Active', 'Most Followed', 'Most Viewed'],
          },
          filteringCategories: {},
        }
      } else if (isDiagramsFilter(params.filter)) {
        return {
          ...currentResult,
          searchPlaceholder: searchSomething.replace('{target}', 'Diagrams'),
          title: wordCapitalDiagrams,
          class: params.filter,
          sortingCategories: {
            selectedItem: 'Most Appreciated',
            items: ['Most Appreciated', 'Most Viewed', 'Most Recent'],
          },
          filteringCategories: {},
        }
      }
    }
  },

  async getDiagrams(
    { rootGetters, dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: {
        filter: string
        searchTerm: string
        tagId: string
        pageNo: number
        source: string
      }
    }
  ): Promise<any> {
    let result = {} as any
    let resultDiagrams = [] as any
    let totalNoDiagrams = 0

    try {
      if (params?.tagId?.length > 0) {
        return await dispatch('getDiagramsByTags', {
          context,
          params,
        })
      } else {
        if (params?.searchTerm?.length > 0) {
          result = await dispatch(
            DiagramStoreActions.GET_SEARCH_DIAGRAMS,
            {
              context,
              params: {
                searchText: params.searchTerm,
                page: params.pageNo,
                count: CARDS_PER_PAGE,
              },
            },
            { root: true }
          )

          resultDiagrams = result.diagrams
          totalNoDiagrams = result.total
        } else {
          result = await dispatch(
            DiagramStoreActions.GET_DIAGRAMS,
            {
              context,
              params: {
                diagramPage: params.pageNo,
                authenticatedUser: rootGetters.userIsAuthenticated,
                count: CARDS_PER_PAGE,
                source: params.source,
              },
              doNotSetInStore: true,
            },
            { root: true }
          )

          resultDiagrams = result.list
          totalNoDiagrams = result.totalCount
        }
      }
    } catch (err) {
      resultDiagrams = []
      totalNoDiagrams = 0
    }

    return {
      results: resultDiagrams,
      totalResults: totalNoDiagrams,
    }
  },

  async getDiagramsByTags(
    { dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: {
        filter: string
        searchTerm: string
        tagId: string
        pageNo: number
        source: string
      }
    }
  ): Promise<any> {
    let result = {} as any
    let resultDiagrams = [] as any
    let totalNoDiagrams = 0
    try {
      const tag: Tag = {
        name: params.searchTerm?.toString(),
        id: parseInt(params.tagId?.toString()),
      }

      result = await dispatch(
        DiagramStoreActions.GET_DIAGRAMS_BY_TAGS,
        {
          context,
          tag: tag,
        },
        { root: true }
      )
      resultDiagrams = result.list
      totalNoDiagrams = result.totalCount
    } catch (error) {
      resultDiagrams = []
      totalNoDiagrams = 0
    }

    return {
      results: resultDiagrams,
      totalResults: totalNoDiagrams,
    }
  },

  async getDesigners(
    { rootGetters, dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: {
        filter: string
        searchTerm: string
        tagId: string
        pageNo: number
        source: string
      }
    }
  ): Promise<any> {
    let result = {} as any
    let resultDesigners = [] as any
    let totalNoDesigners = 0

    try {
      if (params.searchTerm) {
        result = await dispatch(
          DesignersStoreActions.SEARCH_DESIGNERS,
          {
            context,
            params: {
              searchText: params.searchTerm,
              page: params.pageNo,
              count: CARDS_PER_PAGE,
            },
          },
          { root: true }
        )

        resultDesigners = result.users
        totalNoDesigners = result.total
      } else {
        result = await dispatch(
          DesignersStoreActions.FETCH_DESIGNERS,
          {
            context,
            page: params.pageNo,
            authenticatedUser: rootGetters.userIsAuthenticated,
            count: CARDS_PER_PAGE,
            excludePopularTeams: true,
            allUsersCount: true,
            doNotSetInStore: true,
          },
          { root: true }
        )

        resultDesigners = result.list
        totalNoDesigners = result.totalCount
      }
    } catch (err) {
      resultDesigners = []
      totalNoDesigners = 0
    }

    return {
      results: resultDesigners,
      totalResults: totalNoDesigners,
    }
  },

  async getDesignersForHire(
    { dispatch },
    context: {
      context: Context
    }
  ): Promise<any> {
    let result = {} as any
    let resultDesigners = [] as any
    let totalNoDesigners = 0

    try {
      result = await dispatch(DesignersStoreActions.FETCH_FOR_HIRE, context, {
        root: true,
      })

      resultDesigners = result.list
      totalNoDesigners = result.totalCount
    } catch (err) {
      resultDesigners = []
      totalNoDesigners = 0
    }

    return {
      results: resultDesigners,
      totalResults: totalNoDesigners,
    }
  },

  async getDesignersOfTheMonth(
    { dispatch },
    context: {
      context: Context
      params: {
        filter: string
        searchTerm: string
        tagId: string
        pageNo: number
        source: string
      }
    }
  ): Promise<any> {
    let result = {} as any
    let resultDesigners = [] as any
    let totalNoDesigners = 0

    try {
      result = await dispatch(
        DesignersStoreActions.FETCH_DESIGNERS_OF_THE_MONTH,
        context,
        { root: true }
      )

      resultDesigners = result.list
      totalNoDesigners = result.totalCount
    } catch (err) {
      resultDesigners = []
      totalNoDesigners = 0
    }

    return {
      results: resultDesigners,
      totalResults: totalNoDesigners,
    }
  },
}
