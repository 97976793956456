import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import VueGtag from 'vue-gtag'

const gtagPlugin: Plugin = (context, inject) => {
  Vue.use(VueGtag, {
    config: {
      id: context.$config.googleAnalytics4Id || 'G-3KG24W2ZMN',
      params: {
        send_page_view: true,
      },
      enabled: false,
    },
  })

  inject('gtag', VueGtag)
}
export default gtagPlugin
