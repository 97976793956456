

import MobileMenu from '../header/MobileMenu.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'HomepageHeader',
  components: {
    MobileMenu
  },
  data() {
    return {
      mobileMenuVisible: false,
      showProfileBox: false,
      showNavbar: true,
      lastScrollPosition: 0,
      lastSelectedItem: 0,
      menuIsHidden: true,
      appUrl: this.$config.nuxtBaseURL,
    }
  },
  beforeMount() {
    this.$notificationsPluginOptions.eventBus.$on("notification-click", this.handleNotificationClick);
  },

  computed: {
    errorMessage() {
      return this.$store.getters["common/getErrorMessage"];
    },
    user() {
      return this.$store.getters['authenticatedUser']
    },
  },

  watch: {
    errorMessage: function (newVal) {
      if(newVal) {
        setTimeout(() => {
          this.$store.commit('common/updateErrorMessage', undefined)
        }, 3000)
      }
    }
  },

  methods: {
    handleNotificationClick(content: any): void { // eslint-disable-line
      const action = content?.action;
      const diagramOwner = content.payload?.username
      switch (action) {
        case 'toast-link-new-follower':
          const user = content.payload?.userContent?.username
          const profileLink = `${this.$config.nuxtBaseURL}/community/${user}/profile`
          window.open(profileLink, '__blank')
        break;

        case 'toast-link-open-new':
          function sanitizeDiagramName(diagramName: string) {
            return diagramName
                .toLowerCase()
                .replace(/[^a-z\d\s-]+/g, '')
                .replace(/\s+/g, '-')
          }
          const diagramTitle = content.payload?.diagramTitle
          const diagramNameSanitized = sanitizeDiagramName(diagramTitle)
          const diagramUid = content.payload?.diagramUid
          const diagramLink = `${this.$config.nuxtBaseURL}/community/${diagramOwner}/${diagramNameSanitized}-${diagramUid}`
          window.open(diagramLink)
        break;
        default:
        break;
      }
    },
    switchDrawer: function () {
      this.mobileMenuVisible = !this.mobileMenuVisible
      this.setNoscroll(this.mobileMenuVisible)

      /* Hide Intercom pop-up when hamburger menu is visible */
      const intercomPopup = document.getElementsByClassName("intercom-lightweight-app");

      if (intercomPopup?.length > 0 && typeof (intercomPopup[0] as HTMLElement) !== 'undefined') {
        if (this.mobileMenuVisible) {
          (intercomPopup[0] as HTMLElement).style.display = "none";
        } else {
          (intercomPopup[0] as HTMLElement).style.display = "block";
        }
      }
    },
    onScroll: function () {
      if (process.browser) {
        const currentScrollPosition =
            window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
          return
        }
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return
        }
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      }
    },
    setNoscroll(noscroll: boolean) {
      if (noscroll) {
        document.documentElement.style.overflow = "hidden";
        document.body.classList.add('noscroll')
      } else {
        document.documentElement.style.overflow  = "hidden scroll";
        document.body.classList.remove('noscroll')
      }
    },
  },

  mounted() {
    if (process.browser) {
      window.addEventListener('scroll', this.onScroll)
    }
    this.menuIsHidden = false

    const notifWrapper = this.$refs?.notificationsBoxWrapper as HTMLElement
    if (this.user && this.$notifications && notifWrapper) {
      this.$notifications.remount({
        ...this.$notificationsPluginOptions,
        username: this.user.username,
      })
    }

  },
  beforeDestroy() {
    if (process.browser){ 
      window.removeEventListener('scroll', this.onScroll)
    }
    this.setNoscroll(false)
  },
})
