import { isNil, isEmpty } from 'lodash-es'
import { IWpDetailedPost, IWpState } from '@interfaces/WpTypes'
import { ActionTree } from 'vuex'
import { serializeQueryString } from '~/domain/helpers/helpers'

const mapPosts = (that, state, allPosts, ids) =>
  allPosts
    ?.filter((el) => {
      let isValidPost = el.status === 'publish'

      if (state.selectedCategory) {
        isValidPost = el.categories.includes(state.selectedCategory)
      }

      if (isValidPost && !isNil(ids) && !isEmpty(ids)) {
        isValidPost = ids.includes(el.id)
      }

      return isValidPost
    })
    .map(
      ({
        id,
        slug,
        title,
        acf,
        metadata,
        excerpt,
        date,
        tags,
        content,
        categories,
        featured_media,
        thumbnail,
        _embedded,
      }) => ({
        ID: id,
        slug,
        title,
        acf,
        metadata,
        excerpt,
        displayDate:
          that.app && that.app.$dayjs
            ? that.app.$dayjs(date).format('DD/MM/YYYY')
            : '',
        tags,
        content,
        featured_media,
        thumbnail,
        category: categories[0],
        author: {
          id: _embedded.author[0].id,
          name: _embedded.author[0].name,
          url: _embedded.author[0].name,
          link: _embedded.author[0].link,
          slug: _embedded.author[0].slug,
          avatar_urls: _embedded.author[0].avatar_urls,
        },
      })
    )

const updateFeaturedMedia = (context, post, mediaSrc: string, commit) => {
  let src = post.thumbnail
  if (process.env.IS_DEV !== 'true') {
    src = mediaSrc?.replace(`${context.$config.wpBaseURL}`, '')
  }
  const postId = post.id || post.ID

  // console.log("-- action: updateFeaturedMedia", postId)

  if (postId) {
    commit('updatePostThumbnail', {
      postId: postId,
      thumbnail: src,
    })
  }
}

const actions: ActionTree<IWpState, IWpState> = {
  resetSelectedCategory({ commit }) {
    commit('selectCategoryBySlug', 5)
  },

  async getPostsForCategory(
    { state, commit },
    { context, wpBaseURL, currentCategory }
  ) {
    try {
      const pagination = state.pagination,
        { $axios, $config } = context
      const res = await $axios.$get(
          `${$config.nuxtBaseURL}/wp-json/posts/${pagination.itemsPerPage}/${pagination.currentPage}/${currentCategory.id}`
        ),
        posts = mapPosts(this, state, res?.posts, []),
        totalPosts = res?.totalPosts

      commit('updatePosts', posts)
      commit('setPaginationTotal', totalPosts || 1)
    } catch (error) {
      console.error(error)
    }
  },

  async getPosts({ state, commit }, { context, wpBaseURL, ids }) {
    let pagination = state.pagination,
      { $axios, $config } = context

    try {
      commit('updateBusy', true)
      const res = await $axios.$get(
          `${$config.nuxtBaseURL}/wp-json/posts/${pagination.itemsPerPage}/${pagination.currentPage}`
        ),
        allPosts = res?.posts,
        totalPosts = res?.totalPosts

      const posts = mapPosts(this, state, allPosts, ids)
      commit('updatePosts', posts)
      commit('setPaginationTotal', totalPosts || 1)
      commit('updateBusy', false)
      return posts
    } catch (err) {
      console.error(err)
      commit('updateBusy', false)
      return []
    }
  },

  async getCategories({ state, commit }, context) {
    if (Object.keys(state.categories).length) return
    const { $config, $axios } = context
    try {
      commit('updateBusy', true)

      const categories = await $axios.$get(
        `${$config.nuxtBaseURL}/wp-json/post-categories`
      )
      for (let category of categories) {
        commit('appendCategory', category)
      }

      commit('updateBusy', false)

      return categories
    } catch (err) {
      console.error(err)
    }
  },

  // @TODO: Move this to getters
  getPostBySlug({ state }, slugPost) {
    return state.posts.find((post) => post.slug === slugPost)
  },

  async fetchPageBySlug({ commit }, { context, slug }): Promise<any> {
    const { $axios, $config, error, route } = context
    try {
      let queryParams = '',
        postType = 'page'
      if (route?.query?.preview) {
        queryParams = '?' + serializeQueryString(route?.query)
        postType = route?.query?.postType
      }
      const pageDetails = await $axios.$get(
        `${$config.nuxtBaseURL}/wp-json/page/${slug}${queryParams}`
      )
      if (!pageDetails) {
        throw new Error(`wp/actions: PAGE_NOT_FOUND - ${slug}`)
      }

      commit('updatePageContent', pageDetails.content.rendered)
      commit('updatePageTitle', pageDetails['title']?.rendered)
      commit('updatePageHead', pageDetails['yoast_head_json'])

      if (pageDetails.acf) {
        commit('updateAcfData', pageDetails.acf)
        if (pageDetails.acf?.blocks) {
          commit('updatePageAcfBlocks', pageDetails.acf.blocks)
        }
      }
      return pageDetails
    } catch (e) {
      console.error(e)
      error({
        statusCode: 404,
        message: 'Page not found',
      })
      return null
    }
  },

  async fetchDiagrams({ commit }, { context }): Promise<any> {
    const { $config, $axios, error } = context
    const { apiBaseURL } = $config
    try {
      const result = await $axios.$get(
        `${apiBaseURL}/community/diagrams?page=1&count=16&sortOrder=asc&source=files`
      )
      if (!result.content || !result.content.list.length) {
        throw new Error(`NOT_FOUND - community/diagrams`)
      }
      const diagrams = result.content.list
      commit('updateDiagramsListData', diagrams)

      return diagrams
    } catch (e) {
      error({
        statusCode: 404,
        message: 'Page not found',
      })
      return null
    }
  },

  async preparePostDetails(
    { commit },
    { context, slugPost }
  ): Promise<IWpDetailedPost> {
    const { $axios, $config, route } = context

    let queryParams = ''
    if (route?.query?.preview) {
      queryParams = '?' + serializeQueryString(route?.query)
    }

    const post: IWpDetailedPost = await $axios.$get(
      `${$config.nuxtBaseURL}/wp-json/post/${slugPost}${queryParams}`
    )
    if (!post) {
      throw new Error(`POST_NOT_FOUND - ${slugPost}`)
    }

    commit('selectPost', post)
    commit('updatePageTitle', post.title.rendered)
    commit('updatePageHead', post['yoast_head_json'])

    return post
  },

  async navigateBreadcrumb({ state, commit }: any, breadcrumb: string) {
    commit('setBreadcrumbs', breadcrumb)
  },

  async fetchTwitterCode({ commit, state }, { context }): Promise<any> {
    const { $config, $axios } = context
    const { nuxtApiBaseURL } = $config

    try {
      const twitterLayout = state.acf.blocks.find(
        (b) => b.acfFcLayout === 'twitter'
      )

      const tweetIds =
        twitterLayout && twitterLayout.block && twitterLayout.block.tweets
          ? Object.keys(twitterLayout.block.tweets).reduce(
              (acc: string[], key) => {
                const tweet = twitterLayout.block.tweets[key]
                return [...acc, tweet.tweetId]
              },
              []
            )
          : []
      const tweetsUrl = `${nuxtApiBaseURL}/system/tweets/${tweetIds.join('+')}`
      const result = await $axios.$get(tweetsUrl)
      const tweetsData = result

      commit('setTwitterData', tweetsData.tweets)
    } catch (error: any) {
      const mockedTweets = await require('../../server/mocked-tweets.json')
      commit('setTwitterData', mockedTweets)
      return null
    }
  },

  async getUserPosts({ state, commit, dispatch }, { context, slugUser }) {
    try {
      commit('updateBusy', true)
      const { $config, $axios, error } = context
      const pagination = state.pagination
      const res = await $axios.$get(
        `${$config.nuxtBaseURL}/wp-json/posts/author/${slugUser}/${pagination.itemsPerPage}/${pagination.currentPage}`
      )
      if (!res || !res.author) {
        return error({
          statusCode: 404,
          message: 'Page not found',
        })
      }
      const postAuthor = res.author
      commit('updateAuthor', postAuthor)
      commit('updatePageTitle', postAuthor.name)
      // @ts-ignore
      commit('updatePageHead', postAuthor.yoast_head_json)

      const posts = mapPosts(this, state, res.posts, [])
      commit('updatePosts', posts)
      commit('setPaginationTotal', res.totalPosts)

      commit('updateBusy', false)
      return posts
    } catch (err) {
      console.error(err)
      return {}
    }
  },

  async fetchDisclaimer(
    { state, commit, dispatch },
    { context, commitPage = false }
  ) {
    const data = await dispatch('fetchPageBySlug', {
      context,
      slug: 'disclaimer',
    })
    if (data && data.content && data.content.rendered) {
      commit('setDisclaimer', data.content.rendered)
      if (commitPage) {
        commit('updatePageTitle', data.title?.rendered)
        commit('updatePageContent', data.content.rendered)
        commit('updatePageHead', data?.yoast_head_json)
      }
    }
  },

  async setupWpPage({ state, commit, dispatch }, { context, slug }) {
    const { $config } = context
    const { wpBaseURL } = $config

    await dispatch('getCategories', wpBaseURL)
    await dispatch('fetchPageBySlug', { context, slug })
  },

  async fetchWpOptionCTA({ state, commit }, { context, optionName }) {
    try {
      const { $axios, $config } = context
      if (!state.cta || !state.cta?.cta_action) {
        const cta = await await $axios.$get(
          `${$config.nuxtBaseURL}/wp-json/acf/options/cta`
        )
        commit('setCta', cta)
      }
    } catch (ex) {
      console.log('ERR fetching wp options:', ex)
    }
  },
}

export default actions
