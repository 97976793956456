import { Diagram } from "@interfaces/DiagramTypes";
import { BaseRepo } from "./BaseRepo";

export class CommunityRepo extends BaseRepo {

  async markActivityFeedAsViewed(params: {ids: number[], username: string}) {
    const {$axios} = this.nuxtContext;
    const routeMarkViewed = `${this.apiBaseURL}/community/user/mark-activity/`
    this.prepareHeaders($axios)
    const result = await $axios.$post(routeMarkViewed, {
      username: params.username,
      activityIds: params.ids
    })
  }

  async fetchActivityFeed(params: any = {}) {
    const { $axios } = this.nuxtContext;
    // https://my.machinations.io/community/user/get-activity-feed?count=10&page=1&username=
    let routeFeed = `${this.apiBaseURL}/community/user/get-activity-feed`;
    const queryString = Object.keys(params).reduce((acc, key) => {
      if (acc !== "") {
        acc += "&";
      }
      acc += key + "=";

      if (Array.isArray(params[key])) {
        acc += encodeURIComponent(params[key].join(","));
      } else {
        acc += params[key]
      }

      return acc;
    }, "");

    if (queryString !== "") {
      routeFeed += `?${queryString}`;
    }

    this.prepareHeaders($axios);

    const result = await $axios.$get(routeFeed);
    const response = result.content;


    return response;
  }
}
