import { Diagram, Tag } from '@interfaces/DiagramTypes'
import { BaseRepo } from './BaseRepo'

import { getQueryParamsString, validateDiagramFuid } from '@helpers/helpers'

export interface DiagramApiResponse {
  totalCount: number
  list: Diagram[]
}

export class DiagramRepo extends BaseRepo {
  async fetchDiagramCardDetails(params: any = {}): Promise<any> {
    const { $axios } = this.nuxtContext
    let routeDiagramCardDetails = `${this.apiBaseURL}/community/diagram-card/`
    const queryString = getQueryParamsString(params)
    if (queryString !== '') {
      routeDiagramCardDetails += `?${queryString}`
    }
    this.prepareHeaders($axios)

    const result = await $axios.$get(routeDiagramCardDetails, {})
    const response = result.content
    try {
      return response.list[0]
    } catch (error) {
      return null
    }
  }

  async fetchDiagrams(params: any = {}): Promise<DiagramApiResponse> {
    try {
      const { $axios } = this.nuxtContext
      let routeDiagrams = `${this.apiBaseURL}/community/diagrams/`
      const queryString = getQueryParamsString(params)

      if (queryString !== '') {
        routeDiagrams += `?${queryString}`
      }

      this.prepareHeaders($axios)

      params.fileKeys?.flat().forEach((fileKey) => {
        if (!validateDiagramFuid(fileKey)) {
          throw new Error('Invalid file fuid')
        }
      })

      const result = await $axios.$get(routeDiagrams, {})
      const response: DiagramApiResponse = result.content

      return response
    } catch (error: any) {
      return {} as DiagramApiResponse
    }
  }

  async fetchDiagramsData(params: any = {}): Promise<DiagramApiResponse> {
    const { $axios } = this.nuxtContext
    let routeDiagrams = `${this.apiBaseURL}/community/diagrams-data-nocache/`
    const queryString = getQueryParamsString(params)

    if (queryString !== '') {
      routeDiagrams += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeDiagrams, {})
    const response: DiagramApiResponse = result.content

    return response
  }
  async fetchDiagramContributors(
    params: any = {}
  ): Promise<DiagramApiResponse> {
    const { $axios } = this.nuxtContext
    let routeDiagrams = `${this.apiBaseURL}/community/diagram-contributors/`
    const queryString = getQueryParamsString(params)

    if (queryString !== '') {
      routeDiagrams += `?${queryString}`
    }

    params.fileKey?.flat().forEach((fileKey) => {
      if (!validateDiagramFuid(fileKey)) {
        throw new Error('Invalid file fuid')
      }
    })

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeDiagrams, {})
    const response: DiagramApiResponse = result.content

    return response
  }

  async fetchNewTemplates(): Promise<DiagramApiResponse> {
    const { $axios } = this.nuxtContext
    let route = `${this.apiBaseURL}/community/diagrams?source=templates&sortBy=last_modified&sortOrder=desc&page=1&count=4`

    this.prepareHeaders($axios)
    const result = await $axios.$get(route, {})
    const response: DiagramApiResponse = result.content
    return response
  }

  async searchDiagrams(params: {
    searchText: string
    page: number
    count: number
  }): Promise<any> {
    try {
      const { $axios } = this.nuxtContext
      let route = `${this.apiBaseURL}/community/diagram-search`
      const queryString = this.prepareQueryStringFrom({
        query: params.searchText,
        page: params.page,
        count: params.count,
      })
      if (queryString !== '') {
        route += `?${queryString}`
      }
      this.prepareHeaders($axios)
      const result = await $axios.$get(route)
      return {
        diagrams: result.content.list,
        total: result.content.totalCount,
      }
    } catch (error: any) {
      console.error('ERROR: ', error)
    }
  }

  async fetchDiagramsByTags(tag: Tag) {
    try {
      const { $axios } = this.nuxtContext
      const route = `${this.apiBaseURL}/community/diagram-search-tag?tag=${tag.name}`
      this.prepareHeaders($axios)
      const result = await $axios.$get(route)
      return result.content
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }
}
