import { DiagramStoreState } from '@interfaces/DiagramTypes'

const diagramsState: DiagramStoreState = {
  highlighted: [],
  newest: [],
  popular: [],
  total: 0,
  totalNewestDiagrams: 0,
  totalPopularDiagrams: 0,
  currentDiagram: null,
  relatedDiagrams: null,
  diagramAction: null,
  highlightedLoaded: false,
  newTemplates: [],
  fetching: false,
  glossary: [],
}

export default () => diagramsState
