
import { mapState } from 'vuex'
import LoginDialog from '@components/dialogs/LoginDialog.vue'

export default {
  name: 'DialogsWrapper',
  components: { LoginDialog },
  computed: {
    ...mapState('dialogs', ['dialogs', 'dialogPayload']),
  },
}
