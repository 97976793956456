
import Vue from 'vue'
import { getProfileUrl } from '~/domain/helpers'
import { UserType } from '~/domain/interfaces'
export default Vue.extend({
  data() {
    return {
      logOut: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['authenticatedUser']
    },
    profileUrl() {
      return getProfileUrl(this.$store.getters['authenticatedUser'])
    },
  },
  methods: {
    logout() {
      this.$auth.logout()
    },
  },
})
