import { PricingStoreState, ProductConfig } from '@interfaces/PricingTypes'
import { PRODUCTS, PRODUCT_KEYS } from '@constants/PricingConst'

export default {
  getPlanById:
    (state: PricingStoreState) =>
    (planId: number): ProductConfig | undefined =>
      state.prodConfig.filter(
        (product: ProductConfig) => product.id === planId
      )?.[0] as ProductConfig,

  getProdConfig: (state: PricingStoreState) => state.prodConfig,

  getPlanFeatures: (state: PricingStoreState) => state.plansFeatures,

  getConfigForActivePlans: (state: PricingStoreState) => (ids?: number[]) => {
    if (state.prodConfig) {
      return state.prodConfig.filter((elem: any) => {
        if (
          elem.active &&
          ((typeof elem.id === 'number' &&
            ids?.length &&
            ids.indexOf(elem.id) !== -1) ||
            !ids?.length)
        ) {
          return true
        }
        return false
      })
    }
    return []
  },

  authenticatedUser: (
    state: PricingStoreState,
    getters,
    rootState,
    rootGetters
  ) => rootGetters.authenticatedUser,

  userIsAuthenticated: (
    state: PricingStoreState,
    getters,
    rootState,
    rootGetters
  ) => rootGetters.userIsAuthenticated,

  currentUserPlanId: (state: PricingStoreState, getters) =>
    getters.authenticatedUser && getters.authenticatedUser.subscriptionPlan
      ? getters.authenticatedUser.subscriptionPlan
      : PRODUCTS.COMMUNITY,

  currentUserPlan: (state: PricingStoreState, getters) => {
    if (getters.authenticatedUser && getters.authenticatedUser.prodConfig) {
      return getters.authenticatedUser.prodConfig
    }
    return getters.getConfigForActivePlans([PRODUCTS.COMMUNITY])?.[0]
  },

  getUpgradePlan: (state: PricingStoreState, getters) =>
    getters.getConfigForActivePlans([PRODUCTS.UPGRADE])?.[0],

  getDisplayedProdConfig:
    (state: PricingStoreState, getters, rootGetters) =>
    (currentPlanTranslated: string, isPartnerPowerPlan: boolean) => {
      // Available plans.
      const activePlans = getters.getConfigForActivePlans(
        Object.keys(PRODUCT_KEYS).map((id: string) => parseInt(id))
      )

      // Current user plan.
      const currentPlanId = getters?.currentUserPlanId;
      const currentPlan = getters?.currentUserPlan;
      if (typeof currentPlanId === 'number') {
        let currentPlanIdx = activePlans.findIndex(
          (plan: ProductConfig) => plan.id === currentPlanId
        )
        if (currentPlanIdx !== -1) {
          activePlans[currentPlanIdx] = { ...currentPlan };
        } else if (currentPlanIdx !== PRODUCTS.COMMUNITY && getters.userIsAuthenticated) {
          const lastId = (activePlans.length - 1) as number;
          // if current plan is different then the existing products replace enterprise with current plan
          activePlans[lastId] = { ...currentPlan };
          activePlans[lastId].name = currentPlanTranslated as string;
        }
      }
      return activePlans
    },

  pricingUserDetails: (state: PricingStoreState, getters) => {
    if (getters.authenticatedUser) {
      let userDetails = getters.authenticatedUser
      return {
        ...userDetails,
        activePlan: userDetails.subscriptionPlan || PRODUCTS.COMMUNITY,
        trialUsed:
          typeof userDetails.trialUsed !== 'undefined'
            ? userDetails.trialUsed
            : true,
      }
    }
    return {
      activePlan: null,
      trialUsed: false,
    }
  },
}
