import { DialogState } from '@interfaces/DialogTypes'

const dialogsStoreState = {
  dialogs: { loginDialog: false, loginDialogSourceAction: '' } as DialogState,
  confirmationDialogResponse: '',
  linkDialogInput: null,
  dialogLabel: null,
  dialogTitle: null,
  dialogPayload: null,
}

export default () => dialogsStoreState
