import { Diagram } from '@interfaces/DiagramTypes'
import { UserType } from '@interfaces/UserTypes'
import { Template } from '@interfaces/TemplateTypes'
import { sanitizeString } from '@helpers/helpers'
import { COMMUNITY_FILTERS } from '@interfaces/CommunityTypes'

export function getUrlSlug(str: string, filterChars = false): string {
  if (str) {
    try {
      const slugParts = str.toLocaleLowerCase().split(' ')
      let result = str
      if (slugParts) {
        result = slugParts.join('-').replaceAll('/', '-')
      }
      return filterChars
        ? encodeURIComponent(result.replaceAll(/[^\w\-._+]+/g, ''))
        : encodeURIComponent(result)
    } catch (ex) {
      console.error(
        `[CommunityHelper] error get slug: ${str} | filterChars: ${filterChars} `,
        ex
      )
    }
  }
  return '-'
}

export function getDiagramFileKey(diagram: Diagram): string {
  if (diagram && diagram.fuid) {
    return diagram.fuid
  }
  if (diagram.publicUrl) {
    const parts = diagram.publicUrl.split('/')
    if (parts.length > 0) {
      return parts[parts.length - 1]
    }
  }
  return ''
}

export function getDiagramUrl(diagram: Diagram, owner?: UserType): string {
  let username = '-'
  const diagramOwner = diagram.owner ? diagram.owner : owner,
    diagramSource = diagram.source
  if (diagramOwner) {
    username = diagramOwner.username
      ? diagramOwner.username
      : diagramOwner.email
      ? diagramOwner.email.substr(0, diagramOwner.email.indexOf('@'))
      : '-'
  }
  const diagramName = diagram.name ? sanitizeString(diagram.name) : 'Untitled'

  let url = `/community/${username}/${getUrlSlug(
    diagramName + '-' + getDiagramFileKey(diagram)
  )}`

  if (diagramSource === 'templates') {
    url = url.concat('/?source=templates')
  }

  return url
}

export const instanceOfDiagram = (obj: Diagram | Template) => {
  return typeof obj === 'object' && 'fuid' in obj
}

export function getDiagramViewUrl(
  diagram: Diagram | Template,
  owner?: UserType
) {
  if (instanceOfDiagram(diagram))
    return getDiagramUrl(diagram as Diagram, owner)
  else
    return `/templates/${(diagram as Template).type}/${
      (diagram as Template).ID
    }?name=${(diagram as Template).title}`
}

export function getProfileUrl(designer: UserType): string {
  return getProfileUrlByUsername(designer.username)
}

export function getProfileUrlByUsername(username: string): string {
  return `/community/${getUrlSlug(username)}/profile`
}

export function getFrontpageUrl(): string {
  return '/community'
}

export function getProfilesUrl(): string {
  return '/designers'
}

export function getProfilesSearchUrl(): string {
  return getProfilesUrl()
}

export function getDiagramsUrl(): string {
  return '/diagrams'
}

export function getDiagramsSearchUrl(): string {
  return getDiagramsUrl()
}

export function getDiagramThumbnailUrl(fuid: string, size = 'small') {
  return `/share-thumbnail/${fuid}/${size}/png`
}

export function getDiagramOpenLink(diagramPublicLink: string) {
  return diagramPublicLink?.replace('/d/', '/open-public/') || ''
}


export function isSeeAllFilter(filter: string): boolean {
  return isDesignersFilter(filter) || isDiagramsFilter(filter)
}

export function isDesignersFilter(filter: string): boolean {
  return [
    COMMUNITY_FILTERS.DESIGNERS as string,
    COMMUNITY_FILTERS.DESIGNERS_FOR_HIRE as string,
    COMMUNITY_FILTERS.DESIGNERS_OF_THE_MONTH as string,
  ].includes(filter as string)
}

export function isDiagramsFilter(filter: string): boolean {
  return [
    COMMUNITY_FILTERS.DIAGRAMS as string,
    COMMUNITY_FILTERS.NEW_TEMPLATES as string,
    COMMUNITY_FILTERS.TAGS as string,
  ].includes(filter as string)
}
