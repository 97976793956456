export const DIAGRAM_NAV_TAGS = [
  'Community',
  'Templates',
  'Webinars',
  'Deconstructions',
  'Frameworks',
  'Tutorials',
]

export const DIAGRAM_ACTIONS = {
  moveToProject: 'move-to-project',
  open: 'open-diagram',
  openNewTab: 'open-diagram-new-tab',
  copyLink: 'copy-link',
  share: 'share-diagram',
  makeACopy: 'make-a-copy',
  rename: 'rename-diagram',
  delete: 'delete-diagram',
  fork: 'fork-diagram',
  like: 'like-diagram',
  unlike: 'unlike-diagram',
  enterEditMode: 'enter-edit-mode',
}
