import { Vue } from 'nuxt-property-decorator'
import ComparePlansPage from '@axten/compare-plans-page'
import UserAvatar from '@axten/user-avatar'
import ColorPicker from '@axten/color-picker'
import DesignerCard from '@axten/designer-card'
import DiagramCard from '@axten/diagram-card'
import FollowBtnPack from '@axten/follow-btn'
import ActivityFeedCard from '@axten/activity-feed-card'
import AuthorizationModule from '@axten/authorization-module'
import NotificationsPlugin from '@axten/notifications-box'
import CookiesController from '@axten/cookies-controller'
import MTooltip from '@axten/m-tooltip'
import PlanCard from '@axten/plan-card'
import vuetify from './vuetify'

import { NOTIFICATIONS_ROUTES } from '@constants/NotificationsConst'
import { formatDateFromNow } from '~/domain/helpers/formatters'

const components = {
  UserAvatar,
  ComparePlansPage,
  ColorPicker,
  DesignerCard,
  DiagramCard,
  FollowBtnPack,
  ActivityFeedCard,
  NotificationsPlugin,
  CookiesController,
  MTooltip,
  PlanCard,
  AuthorizationModule,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

const mEventBus = new Vue()

// (context, inject)
export default ({ app }, inject) => {
  const i18n = app.i18n

  app.$axios.interceptors.response.use(function (response) {
    if (
      response &&
      response.config &&
      response.config.url &&
      response.config.url.includes(NOTIFICATIONS_ROUTES.GET_NOTIFICATIONS)
    ) {
      if (
        response.data &&
        response.data.statusCode &&
        !(response.data.statusCode >= 200 && response.data.statusCode < 300)
      ) {
        // error
        return null
      }
      if (response.data && response.data.content) {
        return response.data.content
      }
    }
    return response
  })

  app.$axios.push = function (url, data, config) {
    return app.$axios.post(url, data, config)
  }

  const notificationsPluginOptions = {
    mountingPoint: '.m-notifications-box-wrapper',
    appUrl: app.$config.apiBaseURL,
    eventBus: mEventBus,
    axios: app.$axios,
    opened: false,
    username: app.store.getters['authenticatedUser']
      ? app.store.getters['authenticatedUser'].username
      : null,
    language: {
      diagram: i18n.t('diagram'),
      community: i18n.t('communityUrlLabel'),
      notifications: i18n.t('wordCapitalNotifications'),
      viewAll: i18n.t('viewAll'),
      markAsRead: i18n.t('markAllAsRead'),
      noNotifications: i18n.t('youDontHaveNotifications'),
      failedToFetchMessage: i18n.t('failedToFetchNotificationMessage'),
    },
    vuetify: vuetify,
    formatDateFcn: formatDateFromNow,
    environment: 'community',
  }

  inject('notificationsPluginOptions', notificationsPluginOptions)

  Vue.use(NotificationsPlugin, notificationsPluginOptions)
}
