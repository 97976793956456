
import MobileCommunitySubMenu from '../header/MobileCommunitySubMenu.vue'
import { getProfileUrl } from '~/domain/helpers'
import Vue from 'vue'
export default Vue.extend({
  name: 'MobileMenu',
  components: {
    MobileCommunitySubMenu,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItemId: '',
    }
  },

  methods: {
    switchDrawer() {
      this.$emit('switchDrawer')
    },
    mobileMenuItemPressed: function (value: string) {
      if (value === this.menuItemId) {
        this.menuItemId = ''
      } else this.menuItemId = value
    },
    login: function () {
      this.$auth.login()
    },
    logout() {
      this.$auth.logout()
    },
    logoPressed() {
      this.$router.push('/')
    },
    menuHasChildren(menuItem) {
      return (
        (menuItem && menuItem?.children?.length) ||
        menuItem?.name === 'Community'
      )
    },
    getAbsolutePath(slug: string) {
      return slug.startsWith('/') ? slug : `/${slug}`;
    }
  },
  computed: {
    commonMenu() {
      return this.$store.state.common.menu
    },
    userIsAuthenticated() {
      return this.$store.getters['userIsAuthenticated']
    },
    fetchUserComplete() {
      return this.$store.getters['fetchUserComplete']
    },
    registerUrl() {
      return `${this.$config.apiBaseURL}/register`
    },
    user() {
      return this.$store.getters['authenticatedUser']
    },
    profileUrl() {
      return getProfileUrl(this.$store.getters['authenticatedUser'])
    },
  },
})
