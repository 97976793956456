import {
  ActivityFeedItem,
  CommunityStoreState,
  UserType,
} from '@interfaces/index'

export default {
  equalsAuthenticatedUser:
    (state: CommunityStoreState) =>
    (designer: UserType): boolean => {
      return state.user && designer && state.user.username === designer.username
    },

  userIsAuthenticated: (state: CommunityStoreState): boolean =>
    typeof state.user !== 'undefined' &&
    state.user &&
    typeof state.user.username !== 'undefined' &&
    state.user.username !== null &&
    state.user.username !== '',

  spotlightsCount: (state: CommunityStoreState) =>
    state.wpData && state.wpData.spotlights
      ? state.wpData.spotlights.length
      : 0,

  activityFeeds: (state: CommunityStoreState): ActivityFeedItem[] =>
    state.activityFeed.list,
}
