export default () => {
  if (process.client && window) {
    window.addEventListener("message", (message) => {
      if (message && message.data && message.data.eventType === "customScrollEvent" && message.data.eventProps) {
        window.scrollBy({
          top: message.data.eventProps.deltaY || 0,
          left: message.data.eventProps.deltaX || 0,
        });
      }
    });
  }
}
