
import Vue from 'vue'
import BasicDialog from '@components/dialogs/BasicDialog.vue'
import CustomScheme from '../../schemes/customScheme'


const LoginDialog = Vue.extend({
  name: 'LoginDialog',
  components: {
    BasicDialog,
  },
  props: {
    sourceAction: {
      type: String,
      required: true,
    },
    diagramOpenLink: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      registrationObject: {
        environment: this.$config.environment,
        compact: true,
        appConfig: {
          registerUrl: `${this.$config.apiBaseURL}/oauth2/register`,
          environment: this.$config.environment,
          community_url: '',
          appUrl: this.$config.apiBaseURL,
        },
        email: '',
        password: '',
        errorMessage: '',
        loading: false,
        registerFields: [] as any[],
        showErrorMessage: false,
        showGoogle: true,
        reCaptchaKey: this.$config.recaptcha_site_key,
        successMessage: '',
        translatables: {
          registerTitle: 'Register to Machinations',
          termsOfServiceRegistration: 'I have read and agreed to the',
          termsAndConditionsUrlLabel: 'Terms of Service',
          wordCapitalRegister: 'Create account',
          wordOr: 'or',
          registerWithGoogle: 'Continue with Google',
          registerExistingAccount: 'Already have an account ?',
          loginWithEmail: ' Log In',
        },
        validForm: false,
      },
    }
  },
  methods: {
    authBtnPressed(data: {
      valid: boolean
      email: string
      password: string
      recaptchaToken: string
    }) {
      if (data.valid) {
        (this.$auth.strategies.customStrategy as unknown as CustomScheme)
          .register(data.email, data.password)
          .then((response) => {
            if (this.sourceAction === "like this diagram" && this.diagramOpenLink) {
              window.open(this.diagramOpenLink, "_blank")
            }
          })
          .catch((error) => {
            this.$store.commit("common/updateErrorMessage", error.message);
          })
          .finally(() => {
            this.$store.commit('dialogs/showDialog', {
              dialogName: 'loginDialog',
              show: false,
            })
          })
      }
    },

    valueChanged(data: { itemName: string; value: string }) {
      const newRegistrationObj = JSON.parse(
        JSON.stringify(this.registrationObject)
      )
      for (const [key, value] of Object.entries(newRegistrationObj)) {
        if (key === data.itemName) newRegistrationObj[key] = data.value
      }
      this.registrationObject = newRegistrationObj
    },

    showLogin() {
      if (this.sourceAction === "like this diagram" && this.diagramOpenLink) {
        const windowRef = window.open(this.diagramOpenLink, '_blank')
        if (!windowRef) {
          document.location.href = this.diagramOpenLink
        }
      } else {
        this.$auth.login()  
      }
      // Close registration dialog.
      this.$store.commit('dialogs/showDialog', {
        dialogName: 'loginDialog',
        show: false,
      })
    }
  }
})

export default LoginDialog;
