import { DialogsStoreState } from '@interfaces/DialogTypes'

export default {
  showDialog(
    state: DialogsStoreState,
    { dialogName = '', show = false, sourceAction = '', dialogPayload = null } // eslint-disable-line
  ): void {
    switch (dialogName) {
      case 'loginDialog':
        state.dialogs.loginDialog = show
        state.dialogs.loginDialogSourceAction = sourceAction
        break
      default:
        break
    }
    state.dialogPayload = dialogPayload
  },
  hideAllDialogs(state: DialogsStoreState): void {
    Object.keys(state.dialogs).forEach(function (key: string) {
      ;(state.dialogs as any)[key] = false //eslint-disable-line
    })
  },
  setConfirmationDialogResponse(state: DialogsStoreState, value: string): void {
    state.confirmationDialogResponse = value
  },
  setLinkDialogInput(state: DialogsStoreState, value = null): void {
    state.linkDialogInput = value
  },
  clearDialogPayload(state: DialogsStoreState): void {
    state.dialogPayload = null
  },
}
