
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'BasicDialog',
})
export default class BasicDialog extends Vue {
  @Prop({ default: null }) private dialogName!: string
  @Prop({ default: true }) private closable!: boolean
  @Prop({ default: true }) private overlay?: boolean
  @Prop({ default: 13 }) private closeIconSize?: number
  @Prop({ default: () => '9000' }) private zIndex?: string

  // private closeIcon = mdiClose;
  private hideDialog(): void {
    this.$store.commit("dialogs/showDialog", {
      dialogName: this.dialogName,
      show: false,
    })
    removeEventListener('keyup', this.checkIfEsc)
    this.$emit('close-dialog')
  }

  checkIfEsc(evt: KeyboardEvent): void {
    if (evt.key === 'Escape' && this.closable) {
      this.hideDialog()
      evt.stopPropagation()
      evt.preventDefault()
    }
  }

  onOverlayClick(evt: MouseEvent): void {
    const target = evt.target as HTMLElement
    if (target && target.classList.contains('v-overlay__scrim')) {
      this.hideDialog()
    }
  }

  mounted(): void {
    addEventListener('keyup', this.checkIfEsc)
    addEventListener('mousedown', this.onOverlayClick)
  }

  hasHeaderSlot(): boolean {
    if (this.$slots.header) return true
    return false
  }

  hasFooterSlot(): boolean {
    if (this.$slots.footer) return true
    return false
  }
}
