import { COOKIE_DOMAIN } from '~/domain/constants'
import { Context } from '@nuxt/types'
import {
  TRACKING_CATEGORY,
  TRACKING_TITLE,
  TRACKING_ACTION,
} from '~/domain/constants/TrackingConst'
import { BaseRepo } from './BaseRepo'
import { v4 as uuidv4 } from 'uuid'
import jwt_decode, { JwtPayload } from 'jwt-decode'

export type SegmentPageMessage = {
  name: string
  category: TRACKING_CATEGORY
  misc: any[]
  id: string
  title: string
  url: string
  referrer: string
  msgAttribs: {
    environment: string
    navigator: string
    type: string
    integrations: string
  }
  anonymusId: string
  userId: string
  utm_source?: string | undefined
  utm_medium?: string | undefined
  utm_campaign?: string | undefined
}

export type SegmentMessage = {
  name: TRACKING_TITLE
  category: TRACKING_CATEGORY
  action: TRACKING_ACTION
  label: string | null
  value: number | undefined
  misc: any[]
  id: string
  msgAttribs: {
    environment: string
    navigator: string
    type: string
    page: {
      url: string
    }
    integrations: string
  }
  anonymousId: string
  userId: string
  utm_source?: string | undefined
  utm_medium?: string | undefined
  utm_campaign?: string | undefined
}

export type SegmentEventParams = {
  link?: string | null | undefined //tbd
  name: TRACKING_TITLE
  category: TRACKING_CATEGORY
  action: TRACKING_ACTION
  label: string //tbd
  value?: number | undefined
  misc: any[] //to be defined
  callback?: (params: any) => void //params to be defined
  openInNewTab?: boolean
  integrations?: any //to be defined
}

export type SegmentPageParams = {
  name: TRACKING_TITLE
  category: TRACKING_CATEGORY
  misc: any[]
  callback: (params: any) => void
  integrations: any
}

export type SegmentIdentifyParams = {
  integrations: any
}

export class SegmentRepo extends BaseRepo {
  trackingWebhookUrl = `${this.apiBaseURL}/message`

  messageIdPrefix = 'www-'
  messageType = 'segmentMsg'
  pageMessageType = 'segmentMsgPage'
  identifyMessageType = 'segmentMsgIdentify'
  UNIQUE_USER_IDENTIFIER_COOKIE = 'ajs_anonymous_id'
  SEGMENT_USER_ID_COOKIE = 'ajs_user_id'
  AUTH_ACCESS_TOKEN_COOKIE = 'authToken'

  POST_TIMEOUT = 2000
  REDIRECT_TIMEOUT = 500

  UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign']

  segment = null
  hostname = ''
  environment = ''

  constructor(context: Context, segment: any, hostname: string) {
    super(context)
    this.segment = segment
    this.hostname = hostname
    this.environment = context.$config.environment
  }

  generateMessageId(messageType, userId = ''): string {
    return `${this.messageIdPrefix}-${messageType}-${userId}-${uuidv4()}`
  }

  getCookieValue(cookie_label) {
    let value = this.nuxtContext.$cookies.get(cookie_label)
    if (value) {
      value = decodeURIComponent(value).replace(/"/g, '').replace(/'/g, '')
    }
    return value
  }

  getExpirationDate(cookieName) {
    let date = new Date(),
      expireAfter =
        cookieName === 'utm_campaign'
          ? 180 * 24 * 60 * 60 * 1000
          : cookieName === this.UNIQUE_USER_IDENTIFIER_COOKIE
          ? 365 * 24 * 60 * 60 * 1000
          : 180 * 24 * 60 * 60 * 1000

    date.setTime(date.getTime() + expireAfter)
    return date
  }

  setUniqueVisitorCookie(userId?: string | number) {
    if (userId) {
      // Reset cookies if user has switched accounts:
      const cookieUserId = this.getCookieValue(this.SEGMENT_USER_ID_COOKIE)
      if (cookieUserId && cookieUserId != userId) {
        const newValue = this.resetUserIdentifierCookies(userId)
        return newValue
      }
    }
    // Get ajs_anonymous_id, if present.
    let uniqueId = this.getCookieValue(this.UNIQUE_USER_IDENTIFIER_COOKIE)
    if (!uniqueId) {
      uniqueId = this.resetUserIdentifierCookies(userId)
    } else {
      uniqueId = decodeURIComponent(uniqueId)
        .replace(/"/g, '')
        .replace(/'/g, '')
    }
    return uniqueId
  }

  resetUserIdentifierCookies(userId?: string | number) {
    const uniqueId = uuidv4()
    this.nuxtContext.$cookies.set(
      this.UNIQUE_USER_IDENTIFIER_COOKIE,
      uniqueId,
      {
        expires: this.getExpirationDate(this.UNIQUE_USER_IDENTIFIER_COOKIE),
        path: '/',
        domain: COOKIE_DOMAIN,
      }
    )
    if (userId) {
      this.nuxtContext.$cookies.set(this.SEGMENT_USER_ID_COOKIE, userId, {
        expires: this.getExpirationDate(this.UNIQUE_USER_IDENTIFIER_COOKIE),
        path: '/',
        domain: COOKIE_DOMAIN,
      })
    }
    if (this.segment) {
      //@ts-ignore
      this.segment?.setAnonymousId?.(uniqueId)
    }
    // TODO: Reset user details in localstorage.
    return uniqueId
  }

  addUtmParamsToMessage(
    message: SegmentMessage | SegmentPageMessage
  ): SegmentMessage | SegmentPageMessage {
    //@ts-ignore
    if (this.nuxtContext.$cookies) {
      const cookies = document.cookie.split('; ')
      this.UTM_PARAMS.forEach((param) => {
        //@ts-ignore
        let value = this.nuxtContext.$cookies.get(param)
        if (value) {
          message[param] = value
        }
      })
    }
    return message
  }

  getUtmParamsForRedirect() {
    try {
      //@ts-ignore
      if (this.nuxtContext.$cookies) {
        let queryString = ''
        this.UTM_PARAMS.forEach((param) => {
          //@ts-ignore
          let value = this.nuxtContext.$cookies.get(param)
          if (value) {
            queryString +=
              (queryString !== '' ? '&' : '') +
              `${param}=${encodeURI(value[1])}`
          }
        })
        return queryString
      }
    } catch (error) {
      console.error('EXCEPTION UTM PARAMS FOR REDIRECT: ', error)
    }
    return ''
  }

  async trackSegmentEvent(params: SegmentEventParams) {
    const uniqueVisitorCookieVal = this.setUniqueVisitorCookie()
    const userId = this.getCookieValue(this.SEGMENT_USER_ID_COOKIE)
    const messageId = this.generateMessageId(this.messageType)
    let message: SegmentMessage = {
      name: params.name,
      category: params.category,
      action: params.action,
      label: params.label,
      value: params.value,
      misc: params.misc,
      id: messageId,
      msgAttribs: {
        environment: this.environment,
        //@ts-ignore
        navigator: `${this.nuxtContext.$ua.deviceType()} ${this.nuxtContext.$ua.os()}/${this.nuxtContext.$ua.osVersion()} ${this.nuxtContext.$ua.browser()}/${this.nuxtContext.$ua.browserVersion()} ${this.nuxtContext.$ua.browserVendor()}`,
        type: this.messageType,
        page: {
          // TODO: community/_communityUser - $route or route not defined
          // @ts-ignore
          url: `${this.hostname}${this.nuxtContext.$route.fullPath}`,
        },
        integrations: params.integrations,
      },
      anonymousId: uniqueVisitorCookieVal,
      userId: userId,
    }
    message = this.addUtmParamsToMessage(message) as SegmentMessage
    // const data = `messages=${encodeURIComponent(JSON.stringify([message]))}`

    try {
      // @ts-ignore
      this.nuxtContext.$gtag.event(
        params.action, {
          event_category: params.category,
          event_label: params.label,
          value: params.value,
        }
      )
    } catch (error) {
      console.warn('Error from sending track event message: ', error);
    }
    

    const result = await this.nuxtContext.$axios.post(
      this.trackingWebhookUrl,
      {
        messages: [message],
      }
      // {timeout: this.POST_TIMEOUT}
    )

    if (params.link) {
      let link = params.link
      if (params.link.includes(this.apiBaseURL)) {
        const utmQueryString = this.getUtmParamsForRedirect()
        link = params.link + (utmQueryString ? '?' + utmQueryString : '')
      }
      // window.open(link, params.openInNewTab ? '_blank' : '_self')
    } else if (params.callback && typeof params.callback === 'function') {
      params.callback(null)
    }
  }

  async pageSegmentEvent(params: SegmentPageParams) {
    const uniqueVisitorCookieVal = this.setUniqueVisitorCookie()
    const userId = this.getCookieValue(this.SEGMENT_USER_ID_COOKIE)
    let message: SegmentPageMessage = {
      name: params.name,
      category: params.category,
      misc: params.misc,
      id: this.generateMessageId(this.pageMessageType),
      title: params.name,
      url: this.nuxtContext.route.fullPath,
      anonymusId: uniqueVisitorCookieVal,
      msgAttribs: {
        environment: this.environment,
        integrations: params.integrations,
        //@ts-ignore
        navigator: `${this.nuxtContext.$ua.deviceType()} ${this.nuxtContext.$ua.os()}/${this.nuxtContext.$ua.osVersion()} ${this.nuxtContext.$ua.browser()}/${this.nuxtContext.$ua.browserVersion()} ${this.nuxtContext.$ua.browserVendor()}`,
        type: this.pageMessageType,
      },
      referrer: document.referrer ? document.referrer : '',
      userId: userId,
    }
    message = this.addUtmParamsToMessage(message) as SegmentPageMessage
    const data = `message=${encodeURIComponent(JSON.stringify([message]))}`
    const result = await this.nuxtContext.$axios.$post(
      this.trackingWebhookUrl,
      data,
      { timeout: this.POST_TIMEOUT }
    )

    //@ts-ignore
    // this.nuxtContext.$ga.page(this.nuxtContext.route?.fullPath)
  }

  async identifySegmentEvent(params: any) {
    if (params && params.user) {
      const anonymousId = this.setUniqueVisitorCookie(params.user.id)
      const userId = params.user.id || this.getCookieAuthUserId() // this.getCookieValue('ajs_user_id')

      const traitsObject = {
        anonymousId: anonymousId,
        userId: params.user.id,
        email: params.user.email,
        firstName: params.user.firstname,
        lastName: params.user.lastname,
      }

      // Identify client-side
      if (this.segment) {
        //@ts-ignore
        this.segment.identify(params.user.id, traitsObject)
      }

      // Identify server-side
      let message = {
        id: this.generateMessageId(this.identifyMessageType),
        msgAttribs: {
          environment: this.environment,
          //@ts-ignore
          navigator: `${this.nuxtContext.$ua.deviceType()} ${this.nuxtContext.$ua.os()}/${this.nuxtContext.$ua.osVersion()} ${this.nuxtContext.$ua.browser()}/${this.nuxtContext.$ua.browserVersion()} ${this.nuxtContext.$ua.browserVendor()}`,
          type: this.identifyMessageType,
          page: {
            // @ts-ignore
            url: `${this.hostname}${this.nuxtContext.$route?.fullPath}`,
          },
          integrations: params.integrations,
        },
        anonymousId: anonymousId,
        userId: userId,
      }

      await this.nuxtContext.$axios.post(this.trackingWebhookUrl, {
        messages: [message],
      })
    }
  }

  getCookieAuthUserId(): string {
    try {
      const prefixToken = this.nuxtContext.$cookies.get('prefixToken')
      const authToken = this.nuxtContext.$cookies.get(`${prefixToken}authToken`)
      const token: JwtPayload = jwt_decode(authToken)
      if (token && token.sub) {
        return token.sub
      }
    } catch (ex) {
      console.error('getCookieUserId error:', ex)
    }
    return ''
  }
}
