import {
  Diagram,
  DiagramAction,
  DiagramStoreState,
} from '@interfaces/DiagramTypes'
import { DIAGRAM_ACTIONS } from '~/domain/constants'
import Vue from 'vue'

export default {
  setIsFetching(state: DiagramStoreState, fetching: boolean) {
    state.fetching = fetching
  },
  setCurrentDiagram(state: DiagramStoreState, diagram: Diagram | null): void {
    state.currentDiagram = diagram
  },
  setNewTemplates(state: DiagramStoreState, newTemplates: Diagram[]): void {
    state.newTemplates = newTemplates
  },
  setRelatedDiagrams(
    state: DiagramStoreState,
    diagrams: Diagram[] | null
  ): void {
    state.relatedDiagrams = diagrams
  },
  setNewest(
    state: DiagramStoreState,
    params: { diagrams: Diagram[]; total: number }
  ): void {
    state.newest = params.diagrams
    state.totalNewestDiagrams = params.total
  },
  setPopular(
    state: DiagramStoreState,
    params: { diagrams: Diagram[]; total: number }
  ): void {
    state.popular = params.diagrams
    state.totalPopularDiagrams = params.total
  },
  setHighlighted(state: DiagramStoreState, diagrams: Diagram[]): void {
    state.highlighted = diagrams
  },
  setTotal(state: DiagramStoreState, total: number): void {
    state.total = total
  },
  setDiagramAction(state: DiagramStoreState, action: DiagramAction): void {
    state.diagramAction = action
  },
  setHighlightedLoaded(state: DiagramStoreState, isLoaded: boolean): void {
    state.highlightedLoaded = isLoaded
  },
  updateDiagramLikes(
    state: DiagramStoreState,
    data: { action: DiagramAction; diagram: Diagram }
  ): void {
    const isLiked = data.action.type === DIAGRAM_ACTIONS.like
    const d = data.diagram
    if (d !== null) {
      if (!d.likeCount) {
        d.likeCount = 0
      }
      let likeCountNo =
        typeof d.likeCount === 'string' ? parseInt(d.likeCount) : d.likeCount
      likeCountNo += isLiked ? 1 : -1
      d.likeCount = likeCountNo
      d.likedByAuthenticatedUser = isLiked
    }
  },
  setGlossary(state: DiagramStoreState, glossary: Diagram[]): void {
    state.glossary = glossary
  },
  setIsLiked(state: DiagramStoreState, isLiked: boolean): void {
    if (state.currentDiagram) {
      state.currentDiagram.likedByAuthenticatedUser = isLiked
    }
  },
  setIsFollowed(state: DiagramStoreState, isFollowed: boolean): void {
    if (state.currentDiagram && state.currentDiagram.owner) {
      state.currentDiagram.owner.isFollowed = isFollowed
    }
  },
  setContributors(state: DiagramStoreState, contributors): void {
    if (state.currentDiagram) {
      Vue.set(state.currentDiagram, 'contributors', contributors)
    }
  },
  setLikeCount(state: DiagramStoreState, likeCount: number): void {
    if (state.currentDiagram) {
      state.currentDiagram.likeCount = likeCount
    }
  },
}
