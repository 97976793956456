import {
  Oauth2Scheme,
  Oauth2SchemeOptions,
} from '@nuxtjs/auth-next/dist/runtime'

export type AuthResponseDataType = {
  data: { action: boolean; success: boolean; content: any }
}

export default class CustomScheme extends Oauth2Scheme<Oauth2SchemeOptions> {
  private clientId = 'machinations-website-YtfyJVqjbN'

  private setAxiosHeaders = (accessToken = undefined) => {
    const context = this.$auth.ctx
    context.$axios.setHeader('x-client-id', this.clientId)
    const prefixToken = context.$cookies.get('prefixToken')
    const authToken = context.$cookies.get(`${prefixToken}authToken`)
    if (authToken) {
      const authTokenBearer = 'Bearer ' + authToken
      context.$axios.setHeader('authorization', authTokenBearer)
    } else if (accessToken) {
      const authTokenBearer = 'Bearer ' + accessToken
      context.$axios.setHeader('authorization', authTokenBearer)
    }
  }

  public refreshAuthToken = async () => {
    const context = this.$auth.ctx
    this.setAxiosHeaders()

    const prefixToken = context.$cookies.get('prefixToken')
    const refreshToken = context.$cookies.get(`${prefixToken}refreshToken`)

    const response = await context.$axios
      .$post(context.$config.apiBaseURL + '/oauth2/refresh', {
        refreshToken,
      })
      .then((response) => {
        this.setAxiosHeaders(response.accessToken)
        context.$cookies.set(`${prefixToken}authToken`, response.accessToken, {
          path: '/',
        })
      })
      .catch(() => {
        context.$cookies.remove(`prefixToken`, { path: '/' })
        context.$cookies.remove(`${prefixToken}authToken`, { path: '/' })
        context.$cookies.remove(`${prefixToken}refreshToken`, { path: '/' })
        this.setAxiosHeaders()
        throw new Error('Failed to refresh auth token')
      })
  }

  public register = async (email: string, password: string) => {
    const context = this.$auth.ctx
    this.setAxiosHeaders()
    const response = await context.$axios.$post(
      `${context.$config.apiBaseURL}/oauth2/register`,
      { email: email, password: password },
      { withCredentials: true }
    )
    if (response.success) {
      //user created
      try {
        const segmentData = response.content.segmentData
        segmentData.misc.push({ type: 'location', location: 'www' })
        context.store.dispatch(
          'common/sendTrackEventMessage',
          {
            context: context,
            params: {
              ...segmentData,
            },
          },
          { root: true }
        )
      } catch (error) {
        console.error('Tracking error')
      }
      await this.fetchUser()
      if (process.client) {
        window.open(context.$config.apiBaseURL + `?redirect_uri=/`, '_blank')
      }
    } else {
      context.store.dispatch(
        'common/sendTrackEventMessage',
        {
          context: context,
          params: {
            name: 'Registration - Manual Failure',
            category: 'Failure',
            action: 'Registration',
            misc: [
              { location: 'manual registration' },
              { type: 'location', location: 'www' },
            ],
            label: 'Manual Auth Account registration failed',
          },
        },
        { root: true }
      )
      throw new Error(response.content.error)
    }
  }

  async login(): Promise<void> {
    const context = this.$auth.ctx
    this.setAxiosHeaders()
    await context.$axios
      .get(context.$config.apiBaseURL + '/oauth2/authorize')
      .catch(() => {
        const loginUri = `${context.$config.apiBaseURL}/login`
        const windowRef = window.open(loginUri, '_blank')
        if (!windowRef) {
          document.location.href = loginUri
        }
      })
  }

  async fetchUser(): Promise<void> {
    const setUserResponse = (context, response) => {
      this.$auth.setUser(response)
      this.$auth.$storage.setState('loggedIn', response ? true : false)
      if (process.client) {
        context.store.commit('setFetchUserComplete', true, { root: true })
      }
      context.store.commit('setUserIsFetching', false, { root: true })
    }

    return new Promise(async (resolve) => {
      const context = this.$auth.ctx
      context.store.commit('setUserIsFetching', true, { root: true })
      this.setAxiosHeaders()
      context.$axios
        .$get(context.$config.apiBaseURL + '/oauth2/userinfo')
        .then((response) => {
          setUserResponse(context, response)
          if (response && response?.username) {
            context.store.dispatch('common/sendIdentifyEventMessage', {
              context,
              params: {
                user: { ...response },
              },
            })
          }
          resolve()
        })
        .catch(async (error) => {
          const prefixToken = context.$cookies.get('prefixToken')
          const refreshToken = context.$cookies.get(
            `${prefixToken}refreshToken`
          )
          if (refreshToken) {
            this.refreshAuthToken()
              .then(() => {
                context.$axios
                  .$get(context.$config.apiBaseURL + '/oauth2/userinfo')
                  .then((response) => {
                    setUserResponse(context, response)
                    resolve()
                  })
                  .catch(async (error) => {
                    setUserResponse(context, null)
                    resolve()
                  })
              })
              .catch(async (error) => {
                setUserResponse(context, null)
                resolve()
              })
          } else {
            setUserResponse(context, null)
            resolve()
          }
        })
    })
  }

  async logout(): Promise<void> {
    const context = this.$auth.ctx
    this.setAxiosHeaders()
    //clear interval from store state
    if (context.store.state.renewalTimer) {
      clearInterval(context.store.state.renewalTimer)
    }

    const prefixToken = context.$cookies.get('prefixToken')
    const authToken = context.$cookies.get(`${prefixToken}authToken`)
    const refreshToken = context.$cookies.get(`${prefixToken}refreshToken`)

    await context.$axios
      .$post(context.$config.apiBaseURL + '/oauth2/logout', {
        authToken,
        refreshToken,
      })
      .then((response) => {
        // Do nothing
      })
      .catch(() => {
        // Do nothing, only catch errors
      })

    context.$cookies.remove(`prefixToken`, { path: '/' })
    context.$cookies.remove(`${prefixToken}authToken`, { path: '/' })
    context.$cookies.remove(`${prefixToken}refreshToken`, { path: '/' })
    this.setAxiosHeaders()

    this.$auth.setUser(null)
    this.$auth.$storage.setState('loggedIn', false)
  }
}
