import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function formatDate(x: string | Date, dateFormat = "MMM DD, YYYY"): string {
  return dayjs(x instanceof Date ? x : new Date(x)).format(dateFormat);
}

export function formatDateFromNow(x: string, dateFormat = "MMM DD, YYYY"): string {
  const date = new Date(x);
  if (dayjs().add(-1, "week") > dayjs(date)) {
    return formatDate(x, dateFormat);
  }
  return dayjs(date).fromNow();
}

export function formatNumberWithSuffix(
  x: string | number | null,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = 'en-US'
): string {
  return formatNumber(
    x,
    {
      notation: 'compact',
      compactDisplay: 'short',
      ...options,
    } as Intl.NumberFormatOptions,
    locale
  )
}

export function formatNumber(
  x: string | number | null,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = 'en-US'
): string {
  if (x === null || x === undefined) return ''
  if (typeof x === 'string') x = parseFloat(x)
  if (isNaN(x)) return ''

  return x.toLocaleString(locale, options)
}

export function formatCurrency(
  x: string | number | null,
  currency = 'USD',
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = 'en-US'
): string {
  return formatNumber(
    x,
    {
      notation: 'compact',
      compactDisplay: 'short',
      style: 'currency',
      currency: currency,
      ...options,
    } as Intl.NumberFormatOptions,
    locale
  )
}

export function capitalize(s: string): string {
  return s ? s.charAt(0).toUpperCase() + s.substring(1) : s
}
