import { ICommonState, ICommonMenuItem } from '~/domain/common/types';

export default {
  updateMenu: (state: ICommonState, items: ICommonMenuItem[]) => {
    state.menu = items;
  },
  updateFooterMenu(state: ICommonState, items: ICommonMenuItem[]) {
    state.footerMenu = items;
  },
  updateIsHomepage: (state: ICommonState, isHomepage) => {
    state.isHomepage = isHomepage;
  },
  updateWhiteHeader: (state: ICommonState, isWhite) => {
    state.headerIsWhite = isWhite;
  },
  isBusy: (state: ICommonState, pageBusy) => {
    state.pageBusy = pageBusy;
  },
  markHeroLoaded: (state: ICommonState, isLoaded: boolean) => {
    state.heroLoaded = isLoaded;
  },
  setFormSubmitError: (state: ICommonState, value: boolean) => {
    state.formSubmitError = value;
  },
  updateErrorMessage: (state: ICommonState, errorMessage: string | undefined | null) => {
    state.errorMessage = errorMessage;
  }
};
