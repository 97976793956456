const storeName = 'wp'

export default {
  TRACK_SEGMENT_EVENT: 'common' + '/sendTrackEventMessage',
  TRACK_SIGN_UP_EVENT: 'common' + '/trackSignupEvent',
  TRACK_BOOK_DEMO_EVENT: 'common' + '/trackBookDemoEvent',
  TRACK_WATCH_TESTIMONIAL_EVENT: 'common' + '/trackWatchTestimonialEvent',
  FOLLOW_DESIGNER: 'designers' + '/followDesigner',
  LIKE_DIAGRAM: 'diagrams' + '/likeDiagram',
  GET_DIAGRAM_CARD_DETAILS: 'diagrams' + '/getDiagramCardDetails',
  GET_DIAGRAM_DATA_NOCACHE: 'diagrams' + '/getDiagramCardDataNoCache',
  GET_DIAGRAM_CONTRIBUTORS: 'diagrams' + '/getDiagramCardContributors',
  GET_USER_CARDS_DETAILS: storeName + '/getUserCardsDetails',
}
