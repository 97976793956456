
import Vue, { PropType } from 'vue'
import { ICommonMenuItem } from '~/domain/common/types'

export default Vue.extend({
  name: 'HeaderItemCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array as PropType<ICommonMenuItem[]>,
      default: () => {
        return []
      },
    },
    menuSection: String,
  },
  data() {
    return {
      hoveredSubItemId: 0,
    }
  },
  methods: {
    getAbsolutePath(slug: string) {
      return slug.startsWith('/') ? slug : `/${slug}`;
    }
  }
})
