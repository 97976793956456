export enum TRACKING_CATEGORY {
  COMMUNITY = 'Community',
  MICRO_CONVERSION = 'Micro conversion',
}

export enum TRACKING_TITLE {
  LIKE = 'Community - Like',
  UNLIKE = 'Community - Unlike',
  ACTIVITY_FEED_VIEW = 'Community - Activity feed view',
  FOLLOW = 'Community - Follow',
  UNFOLLOW = 'Community - Unfollow',
  BOOK_DEMO = 'Book a demo - Book a demo intent',
  SIGN_UP_INTENT = 'Registration - Sign up intent',
  WATCH_TESTIMONIAL = 'Testimonial - Watch testimonial',
}

export enum TRACKING_ACTION {
  LIKE = 'Like',
  UNLIKE = 'Unlike',
  ACTIVITY_FEED_VIEW = 'Activity feed view',
  FOLLOW = 'Follow',
  UNFOLLOW = 'Unfollow',
  BOOK_DEMO = 'Book a demo intent',
  SIGN_UP_INTENT = 'Sign up intent',
  WATCH_TESTIMONIAL = 'Watch testimonial',
}

export const SEGMENT_DESTINATIONS = {
  BigQuery: "BigQuery",
  Webhooks: "Webhooks",
  Mixpanel: "Mixpanel",
  FacebookConversionsApi: "Facebook Conversions API",
  CustomerIo: "Customer.io (Actions)",
  MailChimp: "MailChimp",
  Intercom: "Intercom",
  Hotjar: "Hotjar",
  HubSpot: "HubSpot",
  ProfitWell: "ProfitWell",
  LinkedInInsightTag: "LinkedIn Insight Tag",
  Segment: "Segment.io",
};
