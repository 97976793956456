import { IWpState } from '@interfaces/WpTypes'
import { CommunityStoreState, CommunitySlugs } from '@interfaces/CommunityTypes'
import { UserLoginInfoType, UserType } from '@interfaces/UserTypes'
import { Diagram } from '@interfaces/DiagramTypes'
import { activityFeed } from '~/locales/en'

export default {
  setInitialState(
    state: CommunityStoreState,
    data: {
      wpData: {} // eslint-disable-line
      wpAtts: []
      trackSegmentEvent: any // eslint-disable-line
    }
  ): void {
    state.wpData = data.wpData
    state.wpAtts = data.wpAtts
    state.trackSegmentEvent = data.trackSegmentEvent
    if (!state.wpData.machinationsAppUrl) {
      state.wpData.machinationsAppUrl = 'https://staging.machinations.io'
    }
  },
  setUserLoginInfo(
    state: CommunityStoreState,
    data: {
      user: UserLoginInfoType
    }
  ): void {
    state.user = data.user
    state.userLoginSet = true
  },
  setLikeDiagram(
    state: CommunityStoreState,
    data: { fuid: string; liked: boolean; diagramView: boolean }
  ): void {

    const updateDiagram = (diagram: Diagram) => {
      if (diagram.fuid === data.fuid) {
        diagram.likedByAuthenticatedUser = data.liked
        if (!diagram.likeCount) {
          diagram.likeCount = 0
        }
        let likeCountNo =
          typeof diagram.likeCount === 'string'
            ? parseInt(diagram.likeCount)
            : diagram.likeCount
        likeCountNo += data.liked ? 1 : -1
        diagram.likeCount = likeCountNo.toString()
      }
      return diagram
    }

    /**
     *  All under are base on:
     * - source of all diagrams is "state.wpData.diagramTabs"
     * - increment/decrement of the diagrama are on "state.wpData.diagramTabs"-reference Before anything else
     * - the copy of diagram is pushed/pulled out from "state.wpData.userLikedDiagrams" after incerement/decrement - ONLY
     */

    // Wich array of 'diagramTabs' has the diagram with this FUID.
    if (state.wpData.diagramTabs) {
      state.wpData.diagramTabs?.forEach((diagrams, index) => {
        if (
          state.wpData.diagramTabs &&
          typeof state.wpData.diagramTabs[index] !== 'undefined'
        ) {
          state.wpData.diagramTabs.splice(
            index,
            1,
            diagrams.map((d) => updateDiagram(d))
          )
        }
      })
    }

    // Holds If the current diagram is held allready in userLikedDiagrams
    if (state.wpData.userLikedDiagrams) {
      const presentInLikedDiagrams =
        state.wpData.userLikedDiagrams.findIndex(
          (el: Diagram) => el.fuid === data.fuid
        ) !== -1

      if (presentInLikedDiagrams) {
        state.wpData.userLikedDiagrams = state.wpData.userLikedDiagrams?.map(
          (d) => updateDiagram(d)
        )
      }
    }

    // If in diagram view - increass the likeCount on wpData.diagram too.
    if (
      typeof state.wpData.diagram !== 'undefined' &&
      state.wpData.diagram !== null
    ) {
      state.wpData.diagram = updateDiagram(state.wpData.diagram)
    }

    if (state.wpData.relatedDiagrams) {
      state.wpData.relatedDiagrams = state.wpData.relatedDiagrams.map(
        (d: Diagram) => updateDiagram(d)
      )
    }

    // Diagrams owned by user.
    if (state.wpData.userDiagrams) {
      state.wpData.userDiagrams = state.wpData.userDiagrams?.map((d: Diagram) =>
        updateDiagram(d)
      )
    }

    // Activity feed - popular diagrams.
    if (state.wpData.diagrams) {
      state.wpData.diagrams = state.wpData.diagrams?.map((d: Diagram) =>
        updateDiagram(d)
      )
    }
  },

  setActiveSlug(state: CommunityStoreState, slug: CommunitySlugs) {
    state.activeSlug = slug
  },

  setSpotlights(state: CommunityStoreState, spotlights: any[]) {
    state.spotlights = spotlights
  },

  activityFeedAddList(state: CommunityStoreState, activityFeed: any[]) {
    state.activityFeed.list = [...state.activityFeed.list, ...activityFeed]
    // after append check if any is new
    const hasNew = state.activityFeed.list.find((elem) => elem.isNew)
    state.activityFeed.hasNew = hasNew ? true : false
  },

  checkIfHasNew(state: CommunityStoreState) {
    const hasNew = state.activityFeed.list.find((elem) => elem.isNew)
    state.activityFeed.hasNew = hasNew ? true : false
  },

  activityFeedIncrementPage(state: CommunityStoreState) {
    state.activityFeed.lastPage++
  },

  activityFeedResetPage(state: CommunityStoreState) {
    state.activityFeed.lastPage = 1;
  },

  setActivitiesAsViewd(state: CommunityStoreState, id: number) {
    const actFeed = state.activityFeed.list.find(elem => elem.id === id);
    actFeed.isNew = false;
    state.activityFeed.idsViewed = [...state.activityFeed.idsViewed, id]
  },

  resetIdsViewd(state: CommunityStoreState) {
    state.activityFeed.idsViewed = []
  }

}
