import { ICommonState } from "~/domain/common/types";

const commonState: ICommonState = {
  isHomepage: false,
  headerIsWhite: true,
  footerMenu: null,
  menu: null,
  debugMode: true,
  pageBusy: false,
  heroLoaded: false,
  formSubmitError: false,
  errorMessage: undefined
};

export default () => commonState;
