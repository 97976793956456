import { PRODUCT_KEYS } from '~/domain/constants/PricingConst'
import AppActions from '@/domain/constants/AppActions'
import { trackLinkedinAds, planHasCustomQuote } from '~/domain/helpers/helpers'

export default {
  async updateSubscriptionPlan(
    { rootGetters, dispatch },
    { context, planId, billingCycle }
  ): Promise<void> {
    const planConfig = await rootGetters['pricing/getPlanById'](parseInt(planId))
    if (planConfig) {
      const planKeyString = PRODUCT_KEYS[planId]
      const url = `${context.$config.apiBaseURL}/start-trial/${planKeyString}/${billingCycle}`
      trackLinkedinAds('book-demo')
      await dispatch(
        AppActions.TRACK_SEGMENT_EVENT,
        {
          context: context,
          params: {
            name: 'Consideration - Plan Selection',
            category: 'Micro conversion',
            action: 'Selection',
            label: `${planConfig?.name} ${billingCycle} selected`,
            misc: [
              { type: 'location', value: 'web' },
              { type: 'plan', value: planConfig?.name },
              { type: 'period', value: billingCycle },
              { type: 'value', value: planConfig?.price?.[billingCycle] },
            ],
          },
        },
        { root: true }
      )
      if (!planHasCustomQuote(planId)) {
        if (!rootGetters.userIsAuthenticated) {
          await dispatch(
            AppActions.TRACK_SIGN_UP_EVENT,
            {
              context: context,
              location: `pricing_${planConfig?.name?.toLowerCase()}`,
              link: null,
            },
            { root: true }
          )
        }
        window.location.href = url
      } else {
        // opens calendly (from pricing index salesDialog ref)
      }
    } else {
      await dispatch(
        AppActions.TRACK_SIGN_UP_EVENT,
        {
          context: context,
          location: 'pricing',
          link: null,
        },
        { root: true }
      )
      window.location.href = `${context.$config.apiBaseURL}/register`
    }
  },
}
