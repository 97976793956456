import { DiagramStoreState } from '~/domain/interfaces/DiagramTypes'
export default {
  currentDiagram: (state: DiagramStoreState) => state.currentDiagram,
  relatedDiagrams: (state: DiagramStoreState) =>
    state.relatedDiagrams && state.relatedDiagrams.length > 0
      ? state.relatedDiagrams
      : state.highlighted,
  highlighted: (state: DiagramStoreState) => state.highlighted,
  total: (state: DiagramStoreState) => state.total,
}
