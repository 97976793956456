import { IDocsPage } from './../../domain/docs/types';
import { IDocsChapter, IDocsState } from '../../domain/docs/types';
export default {
  updateBusy: (state: IDocsState, busy: boolean) => {
    state.isBusy = busy;
  },

  setChapters: (state: IDocsState, chapters: IDocsChapter[]) => {
    state.chapters = chapters?.map((v) => v); // JSON.parse(JSON.stringify(chapters));
  },

  setChaptersLoaded: (state: IDocsState, isLoaded: boolean) => {
    state.chaptersLoaded = isLoaded;
  },

  registerChapter: (state: IDocsState, chapter: IDocsChapter) => {
    state.chapters.push(chapter);
  },

  selectChapter: (state: IDocsState, chapter: IDocsChapter) => {
    state.selectedChapter = chapter;
  },

  selectPage: (state: IDocsState, page: IDocsPage) => {
    state.selectedPage = {
      ...state.selectedPage,
      ...page
    };
  },

  selectChapterBySlug: (state: IDocsState, slugChapter: string) => {
    state.selectedChapter = state.chapters.find(chapter => chapter.slug === slugChapter) as IDocsChapter;
  },

  resetChapterPages: (state: IDocsState, slugChapter: string) => {
    const chapter = state.chapters.find(chapter => chapter.slug === slugChapter) as IDocsChapter;

    if (!chapter) return;

    chapter.pages = [];
  },

  addPageToChapter: (state: IDocsState, { slugChapter, page }) => {
    const indexChapter = state.chapters.findIndex(chapter => chapter.slug === slugChapter);

    if (indexChapter === -1) {
      return;
    }

    if (!state.chapters[indexChapter].pages) {
      state.chapters[indexChapter].pages = [];
    }

    state.chapters[indexChapter].pages?.push(page);
  }
};
