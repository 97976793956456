import { CookieOption } from '@axten/cookies-controller/dist/types/src/cookies-controller.vue';
import { SEGMENT_DESTINATIONS } from "./TrackingConst";

export const cookieNames: { [key: string]: { name: string; segmentName?: string; managedBySegment?: boolean } } = {
  authentication: { name: "authentication" },
  security: { name: "security" },
  payments: { name: "payments" },
  community: { name: "communityFeed" },
  load: { name: "loadPerformance" },
  googleAnalytics: { name: "googleAnalytics" },
  segment: { name: "segment", segmentName: SEGMENT_DESTINATIONS.Segment },
  hotjar: { name: "hotjar", segmentName: SEGMENT_DESTINATIONS.Hotjar },
  linkedin: { name: "linkedin", segmentName: SEGMENT_DESTINATIONS.LinkedInInsightTag },
  hubspot: { name: "hubspot", segmentName: SEGMENT_DESTINATIONS.HubSpot },
  intercom: { name: "intercom", segmentName: SEGMENT_DESTINATIONS.Intercom },
  mailchimp: { name: "mailchimp", segmentName: SEGMENT_DESTINATIONS.MailChimp, managedBySegment: true },
  profitwell: { name: "profitwell", segmentName: SEGMENT_DESTINATIONS.ProfitWell, managedBySegment: true },
  customerio: { name: "customerio", segmentName: SEGMENT_DESTINATIONS.CustomerIo },
  mixpanel: { name: "mixpanel", segmentName: SEGMENT_DESTINATIONS.Mixpanel },
  bigquery: { name: "bigquery", segmentName: SEGMENT_DESTINATIONS.BigQuery, managedBySegment: true },
};

export const machinationsCookiePreferences = (cookiesConfig: any): { [key: string]: CookieOption[] } => {
  return {
    necessaryCookies: [
      {
        name: cookieNames.authentication.name,
        title: "wordCapitalAuthentication",
        description: "authenticationCookiesDescription",
        value: true,
        readonly: true,
        list: ["auth._refresh_token_expiration.social", "auth._token_expiration.social", "authToken", "refreshToken", "auth._refresh_token.social", "auth.strategy", "_grecaptcha"],
      },
      {
        name: cookieNames.load.name,
        title: "loadPerformance",
        description: "loadPerformanceDescription",
        value: true,
        readonly: true,
        list: ["AWSALB", "AWSALBCORS"],
      },
    ],
    functionalityCookies: [
      {
        name: cookieNames.intercom.name,
        title: "supportChat",
        description: "supportChatDescription",
        value: true,
        readonly: false,
        list: [
          "intercom-device-id-"+cookiesConfig.INTERCOM, 
          "intercom-id-"+cookiesConfig.INTERCOM, 
          "intercom-session-"+cookiesConfig.INTERCOM],
      },
      {
        name: cookieNames.segment.name,
        title: "wordCapitalSegment",
        description: "segmentCookiesDescription",
        value: true,
        readonly: false,
        list: ["ajs_anonymous_id", "ajs_user_id"],
      },
    ],
    optionalCookies: [
      {
        name: cookieNames.googleAnalytics.name,
        title: "googleAnalyticsCookies",
        description: "googleAnalyticsCookiesDescription",
        value: true,
        readonly: false,
        list: ["_ga", "_gid", "_gat_gtag_"+cookiesConfig.GOOGLE_ANALYTICS],
      },
      {
        name: cookieNames.hotjar.name,
        title: "wordCapitalHotjar",
        description: "hotjarCookiesDescription",
        value: true,
        readonly: false,
        list: [
          "_hjAbsoluteSessionInProgress",
          "_hjIncludedInSessionSample_"+cookiesConfig.HOTJAR,
          "_hjSession_"+cookiesConfig.HOTJAR,
          "_hjSessionUser_"+cookiesConfig.HOTJAR,
        ],
        sentViaSegment: true,
        segmentIntegrationName: cookieNames.hotjar.segmentName
      },
      {
        name: cookieNames.hubspot.name,
        title: "wordCapitalHubspot",
        description: "hubspotCookiesDescription",
        value: true,
        readonly: false,
        list: ["__hssrc", "__hstc", "hubspotutk"],
        sentViaSegment: true,
        segmentIntegrationName: cookieNames.hubspot.segmentName
      },
      {
        name: cookieNames.linkedin.name,
        title: "wordCapitalLinkedin",
        description: "linkedinCookiesDescription",
        value: true,
        readonly: false,
        list: ["ln_or"],
        sentViaSegment: true,
        segmentIntegrationName: cookieNames.linkedin.segmentName
      },
      {
        name: cookieNames.mixpanel.name,
        title: "wordCapitalMixpanel",
        description: "mixpanelCookiesDescription",
        value: true,
        readonly: false,
        list: [],
        sentViaSegment: true,
        segmentIntegrationName: cookieNames.mixpanel.segmentName
      },
    ]
  }
  
};