import { IWpState } from '@interfaces/WpTypes'
import { ARTICLES_PER_PAGE } from '~/domain/constants/ArticlesConst';

const wpState: IWpState = {
  isBusy: false,
  allPosts: [],
  posts: [],
  categories: {},
  categoriesSlugs: {},
  selectedCategory: null,
  selectedPost: null,
  pageContent: null,
  pageTitle: null,
  pageHead: {},
  author: null,
  gameData: {
    blocks: [],
  },
  acf: {
    blocks: [],
  },
  acfData: null,
  featuredMedia: null,
  jobsData: {
    blocks: [],
  },
  templatesSections: [
    {
      title: 'Community',
      results: 0,
    },
    {
      title: 'Webinars',
      results: 10,
    },
    {
      title: 'Deconstructions',
      results: 10,
    },
    {
      title: 'Patterns',
      results: 80,
    },
    {
      title: 'Book',
      results: 40,
    },
    {
      title: 'Framework',
      results: 130,
    },
    {
      title: 'Tutorials',
      results: 110,
    },
  ],
  diagrams: {
    diagramsList: [],
    activeDiagram: '',
  },
  breadcrumbs: '',
  spotLights: [],
  pagination: {
    itemsPerPage: ARTICLES_PER_PAGE,
    currentPage: 1,
    total: 1,
  },
  disclaimer: '',
  currentBanner: {
    title: '',
    description: ''
  }
};

export default () => wpState
