import { UserType } from "~/domain/interfaces/UserTypes";

const userDetails: UserType = {
  username: '',
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  jobTitle: '',
  company: '',
  location: {
    city: '',
    country: '',
    countryCode: ''
  },
  avatarUrl: '',
  coverPhotoUrl: '',

  about: '',

  socials: {
    linkedin: '',
    facebook: '',
    discord: '',
    twitter: '',
  },

  subscriptionPlan: 0,

  publicDiagramCount: 0,

  followerCount: 0,
  followedByAuthenticatedUser: false,
  isFollowed: false,
  isPrivateAccount: false,
};

export default () => userDetails;
