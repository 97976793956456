
export default {
  props: ['error'],
  layout: 'error',
  async mounted() {
    const store = this.$store

    await store.dispatch('common/fetchMenu', this)
    store.commit('common/updateWhiteHeader', false)
    store.commit('common/updateIsHomepage', true)
  },
}
