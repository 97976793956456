import {
  SOCIAL_PROVIDERS,
  URL_ARTICLE,
} from '@/domain/constants/CommunityConst'
import { IWpState } from '~/domain/interfaces'

export default {
  getCategroies: (state: IWpState) => {
    return state.categories
  },
  getPosts: (state: IWpState) => state.posts,

  getCategoryNameById: (state) => (idCategory) => {
    return state.categories && state.categories[idCategory]
      ? state.categories[idCategory].name
      : ''
  },
  getCategorySlugById: (state) => (idCategory) => {
    return state.categories && state.categories[idCategory]
      ? state.categories[idCategory].slug
      : ''
  },
  getAcfBlockData: (state: IWpState) => () => {
    return state.pageContent
  },
  getAcfBlocks: (state: IWpState) => state.acf?.blocks,
  getAcfData: (state: IWpState) => state.acfData,
  getPageTitle: (state) => state.pageTitle,
  wpPagination: (state) => state.pagination,
  getPostAuthor: (state) => (post) => {
    return post &&
      post._embedded &&
      Array.isArray(post._embedded.author) &&
      post._embedded.author.length > 0
      ? post._embedded.author[0].name
      : 'Machinations'
  },
  getPostAuthorSlug: (state) => (post) => {
    return post &&
      post._embedded &&
      Array.isArray(post._embedded.author) &&
      post._embedded.author.length > 0
      ? post._embedded.author[0].slug
      : 'machinations'
  },
  getPostShareUrls: (state) => (post, wpBaseUrl) => {
    const url = encodeURIComponent(wpBaseUrl + URL_ARTICLE + '/' + post.slug)
    return {
      [SOCIAL_PROVIDERS.TWITTER]: `https://twitter.com/intent/tweet/?url=${url}&href=${url}&text=${post.title.rendered}`,
      [SOCIAL_PROVIDERS.FACEBOOK]: `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${post.title.rendered}`,
      [SOCIAL_PROVIDERS.LINKEDIN]: `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${post.title.rendered}`,
    }
  },
  getDisclaimer: (state) => state.disclaimer,
  getCtaBlock: (state) => {
    let buttons : Object [] = [];

    for (const buttonKey in state.cta?.cta_action) {
      buttons.push({
        text: state.cta?.cta_action[buttonKey]?.button_link?.title,
        url: state.cta?.cta_action[buttonKey]?.button_link?.url,
        target: state.cta?.cta_action[buttonKey]?.button_link?.target,
        dialog_type: state.cta?.cta_action[buttonKey]?.dialog_type,
        dialog_id: state.cta?.cta_action[buttonKey]?.dialog_id
      })
    }

    return {
      buttons,
      text: state.cta?.cta_description,
      image: [
        state.cta?.image?.sizes['thumbnail'],
        state.cta?.image?.sizes['thumbnail-width'],
        state.cta?.image?.sizes['thumbnail-height']
      ],
    }
  },
}
