
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TrialBadgeIcon extends Vue {
  gradientId = 0;
  mounted() {
    this.gradientId = Date.now();
  }
}
