import { IDocsState } from '@/domain/docs/types'

const docsState: IDocsState = {
  isBusy: false,
  chapters: [],
  selectedChapter: null,
  selectedPage: null,
  chaptersLoaded: false,
}

export default () => docsState
