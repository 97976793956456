import {Context} from '@nuxt/types'
import {SegmentEventParams} from '../../domain/repositories/SegmentRepo'
import {ICommonMenuItem} from '~/domain/common/types'
import {MainAppRepo} from '~/domain/repositories/MainAppRepo'
import {SegmentRepo} from '~/domain/repositories/SegmentRepo'
import {TRACKING_ACTION, TRACKING_CATEGORY, TRACKING_TITLE,} from '~/domain/constants/TrackingConst'
import { trackLinkedinAds } from '~/domain/helpers/helpers'

export default {
  async sendTrackEventMessage(
    { rootGetters },
    { context, params }: { context; params: SegmentEventParams }
  ) {
    const repo = new SegmentRepo(
      context,
      context.$segment,
      rootGetters.hostname
    )
    try {
      await repo.trackSegmentEvent(params)
    } catch (error) {
      console.error('Error from sending track event message: ', error)
    }
  },

  async trackWatchTestimonialEvent(
    { dispatch },
    {
      context,
      location,
      link,
    }: { context: Context; location: string; link: string | null | undefined }
  ) {
    await dispatch('sendTrackEventMessage', {
      context: context,
      params: {
        link: link,
        callback: () => {},
        openInNewTab: false,
        name: TRACKING_TITLE.WATCH_TESTIMONIAL,
        action: TRACKING_ACTION.WATCH_TESTIMONIAL,
        category: TRACKING_CATEGORY.MICRO_CONVERSION,
        label: location,
        misc: [{ type: 'location', value: location }],
        integrations: '',
      },
    })
  },

  async trackSignupEvent(
    { dispatch },
    {
      context,
      location,
      link,
    }: { context: Context; location: string; link: string | null | undefined }
  ) {
    await dispatch('sendTrackEventMessage', {
      context: context,
      params: {
        link: link,
        callback: () => {},
        openInNewTab: false,
        name: TRACKING_TITLE.SIGN_UP_INTENT,
        action: TRACKING_ACTION.SIGN_UP_INTENT,
        category: TRACKING_CATEGORY.MICRO_CONVERSION,
        label: location,
        misc: [{ type: 'location', value: location }],
        integrations: '',
      },
    })
    trackLinkedinAds('signup-intent')
  },

  async trackBookDemoEvent(
    { dispatch },
    {
      context,
      location,
      link,
    }: { context: Context; location: string; link: string }
  ) {
    await dispatch('sendTrackEventMessage', {
      context: context,
      params: {
        link: link,
        callback: () => {},
        openInNewTab: false,
        name: TRACKING_TITLE.BOOK_DEMO,
        action: TRACKING_ACTION.BOOK_DEMO,
        category: TRACKING_CATEGORY.MICRO_CONVERSION,
        label: location,
        misc: [{ type: 'location', value: location }],
        integrations: '',
      },
    })
    trackLinkedinAds('book-demo')
  },

  async sendIdentifyEventMessage(
    { rootGetters },
    { context, params = new Object() }
  ) {
    const repo = new SegmentRepo(
      context,
      context.$segment,
      rootGetters.hostname
    )
    try {
      await repo.identifySegmentEvent(params)
    } catch (error) {
      console.error('[actions] Identify error: ', error)
    }
  },

  async sendContactEmail({ state, commit }, { context, params }) {
    const repo = new MainAppRepo(context)
    try {
      const emailSent = await repo.fetchSendContactEmail(params)
      return emailSent
    } catch (error) {
      console.error('Error sending contact email: ', error)
      return { success: false, action: false, content: error }
    }
  },

  async sendLiveopsWhitepaperEmail({ state, commit }, { context, params }) {
    const repo = new MainAppRepo(context)
    try {
      const emailSent = await repo.fetchSendLiveopsWhitepaperEmail(params)
      console.log('LWE Params: ', emailSent)
      return emailSent
    } catch (error) {
      console.error('Error sending Liveops Whitepaper email: ', error)
      return { success: false, action: false, content: error }
    }
  },

  async fetchMenu({ commit }, context: Context) {
    const { $config, $axios } = context;
    try {
      const menus = await $axios.get(`${$config.nuxtBaseURL}/wp-json/menus`)
      commit('updateMenu', menus?.data?.rootMenuItems);
      commit('updateFooterMenu', menus?.data?.footerMenuItems);
    } catch (err) {
      console.error(err);
    }
  },


  async setupHomepage({ state, commit, dispatch }, context: Context) {
    if (!state.menu) {
      // fetch menu only when is not defined
      await dispatch('fetchMenu', context)
    }

    commit('updateWhiteHeader', true)
    commit('updateIsHomepage', true)
  },

  async commonSetupPage({ state, commit, dispatch }, { context, fetchMenu = true}) {
    if (!state.menu && fetchMenu) {
      // fetch menu only when is not defined
      await dispatch('fetchMenu', context)
    }

    if (!state.headerIsWhite) {
      commit('updateWhiteHeader', true)
    }

    if (state.isHomepage) {
      commit('updateIsHomepage', false)
    }
  },

  async setupPageLight({ dispatch }, { context, fetchMenu = true}) {
    await dispatch('commonSetupPage', { context, fetchMenu })
  },

  async setupPageDark({ dispatch }, { context, fetchMenu = true}) {
    await dispatch('commonSetupPage', { context, fetchMenu })
  },

  async getSearchResults({ state, commit }, { context, params }) {
    const repo = new MainAppRepo(context)
    const searchResults = await repo.fetchSearchResults(params)

    return searchResults
  },

  async getSearchSuggestions({ state, commit }, { context, params }) {
    const repo = new MainAppRepo(context)
    const searchResults = await repo.fetchSearchSuggestions(params)

    return searchResults
  },
}
