import { ContextMenuState } from '~/domain/interfaces/ContextMenuTypes'

const contextMenuState: ContextMenuState = {
  isVisible: false,
  visibleMenu: {},
  target: null,
  positionX: 0,
  positionY: 0,
  isUpdating: false
}

export default () => contextMenuState
