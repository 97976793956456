export const ACTIVITY_FEED = {
  // TODO: CREATE = PUBLISH
  CREATE_DIAGRAM: 'publish-diagram',
  SHARE_DIAGRAM: 'share-diagram',
  LIKE_DIAGRAM: 'like-diagram',
  CLONE_DIAGRAM: 'clone-diagram',
  FOLLOW_USER: 'follow-user',
  UPDATE_DIAGRAM: 'update-diagram',
  NEW_TRENDING_DIAGRAM: 7,
  NEW_TRENDING_USER: 8,
  PUBLISH_TEMPLATE: 'publish-template',
  NEW_TRENDING_TEMPLATE: 10,
};

export const ACTIVITY_FEED_TIMESTAMP_COOKIE = "___machinations_last_open_feed";
