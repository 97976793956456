import { IApContent } from '~/domain/interfaces/MainAppTypes'
import { translateProductConfig } from '~/domain/helpers/helpers'
// import fs from 'fs'

export default {
  async fetchDataFromMainApp(
    { state, commit },
    { context, dataName, propName, error }
  ): Promise<any> {
    const { store, $config, $axios } = context
    const { apiBaseURL } = $config
    let decoded = null
    try {
      const requestUrl = `${apiBaseURL}/community/${dataName}`;
      const result: IApContent = await $axios.$get(
        requestUrl
      )

      if (!result) {
        throw new Error('NOT_FOUND')
      }
      if (result.success === true) {
        decoded = JSON.parse(decodeURIComponent(result.content))
        const data = {
          //   content: propName === "prodConfig" ? translateProductConfig(decoded) : decoded,
          content: decoded,
          propStore: propName,
        }

        // TO DO
        // TRANSLATE data and pick from Translate
        // commit('updateContentBasedOnCall', data)
      } else {
        // pe content e mesajul erorii
        console.error(result.content)
      }

      return decoded
    } catch (e) {
      console.error(e)
      return null
    }
  },
}
