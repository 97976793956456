import { UserType } from "@interfaces/UserTypes";

export enum ActivityFeedType {
  CREATE_DIAGRAM = 'publish-diagram',
  SHARE_DIAGRAM = 'share-diagram',
  LIKE_DIAGRAM = 'like-diagram',
  CLONE_DIAGRAM = 'clone-diagram',
  FOLLOW_USER = 'follow-user',
  UPDATE_DIAGRAM = 'update-diagram',
  NEW_TRENDING_DIAGRAM = 'new-trending-diagram',
  NEW_TRENDING_USER = 'new-trending-user',
  PUBLISH_TEMPLATE = 'publish-template',
  NEW_TRENDING_TEMPLATE = 'new-trending-template',
}

export interface ActivityFeedItem {
  id?: number;
  type: ActivityFeedType;
  details: any; // eslint-disable-line
  author: UserType;
  created: string;
  createdAt: Date;
  isNew?: boolean;
  highlighted?: boolean;
  authorIsPublic?: boolean;
  detailsUserIsPublic?: boolean;
  diagram?: any;
}

export enum ActivityLoadState {
  EMPTY = "",
  LOADING = "loading",
  LOADED = "loaded",
  COMPLETED = "completed",
}

export interface ActivityStoreState {
  activityFeeds: ActivityFeedItem[],
  loadState: ActivityLoadState,
  hasNewActivities: boolean,
}

// export enum ActivityStoreActions {
//   LOAD_ACTIVITY_FEEDS = "activity/loadActivityFeeds",
//   UPDATE_ACTIVITY_FEED_STATUS = "activity/updateActivityFeedStatus",
// }
