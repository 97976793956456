import Vue from 'vue'
import { ContextMenuState } from '~/domain/interfaces/ContextMenuTypes'

export default {
  setIsVisible(
    state: ContextMenuState,
    data: { id: string; visible: boolean; target: any }
  ) {
    state.isVisible = data.visible
    state.target = data.target

    // Close all other previously opened menus
    Object.keys(state.visibleMenu).forEach((key) => {
      if (state.visibleMenu[key] && key !== data.id) {
        Vue.set(state.visibleMenu, key, false)
      }
    })

    // Set visibility for current menu
    Vue.set(state.visibleMenu, data.id, data.visible)
  },
  setIsUpdating(state: ContextMenuState, val: boolean) {
    state.isUpdating = val
  },
}
