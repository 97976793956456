import { cookieNames } from '~/domain/constants/CookiesConst'
import { CookieOption } from '@axten/cookies-controller/dist/types/src/cookies-controller.vue'
import { Context } from '@nuxt/types'

export default {
  async disableIntercom({ commit }, context): Promise<void> {
    try {
      await context.$intercom('shutdown')
    } catch (error) {
      console.error('INTERCOM HIDE ERROR: ', error)
    }
  },

  async enableIntercom({ commit, rootGetters }, context): Promise<void> {
    //enable intercom ui
    try {
      const config = {
        app_id: context.$config.cookiesEnvConfig.INTERCOM,
        hide_default_launcher: false,
      } as {
        app_id: string
        hide_default_launcher: boolean
        user_id?: string | number
        user_hash?: string
      }
      if (
        rootGetters.authenticatedUser &&
        rootGetters.authenticatedUser.id
      ) {
        config.user_id = rootGetters.authenticatedUser.id;
        if (rootGetters.authenticatedUser.user_hash) {
          config.user_hash = rootGetters.authenticatedUser.user_hash
        } else {
          const resp = await context.$axios.$get(`/intercom/identifier/${rootGetters.authenticatedUser.id}`);
          if (resp && resp.user_hash) {
            config.user_hash = resp.user_hash;
          }
        }
      }
      await context.$intercom('boot', config)
    } catch (error) {
      console.error('INTERCOM BOOT ERROR: ', error)
    }
  },

  async enableGa({ commit }, context): Promise<void> {
    try {
      context.$gtag.optIn()
      context.$gtag.pageview({
        page_title: context.$route.meta?.title,
        page_location: context.$route.fullPath,
        page_path: context.$route.path,
      })
    } catch (error) {
      console.error('GOOGLE ANALITYCS ENABLE FAILED: ', error)
    }
  },

  async disableGa({ commit }, context): Promise<void> {
    try {
      context.$gtag.optOut()
      context.$cookies.remove('_ga')
      context.$cookies.remove('_gid')
      context.$cookies.remove('_gat')
    } catch (error) {
      console.error('GOOGLE ANALITYCS DISABLE FAILED: ', error)
    }
  },

  async enableIntegrations(
    { commit, dispatch },
    params: {
      context: Context
      segment: any
      localStorage: Storage
      cookiesData: { [key: string]: CookieOption[] }
    }
  ) {
    for (const key in params.cookiesData) {
      if (key !== 'necessaryCookies') {
        const cookieSection: CookieOption[] = params.cookiesData[key]
        cookieSection.forEach((cookieOption) => {
          //handle non segment cookies: ga & intercom
          switch (cookieOption.name) {
            case cookieNames.intercom.name:
              if (cookieOption.value) dispatch('enableIntercom', params.context)
              else dispatch('disableIntercom', params.context)
              break
            case cookieNames.googleAnalytics.name:
              if (cookieOption.value) dispatch('enableGa', params.context)
              else dispatch('disableGa', params.context)
            default:
              break
          }
        })
      }
    }
  },

  async setCookies(
    { commit, dispatch },
    params: {
      context: Context
      segment: any
      localStorage: Storage
      cookiesData: { [key: string]: CookieOption[] }
    }
  ) {
    dispatch('enableIntegrations', params)
    commit('setCookies', {
      localStorage: params.localStorage,
      cookiesData: params.cookiesData,
    })
  },
}
