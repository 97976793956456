import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Plugin } from '@nuxt/types'

dayjs.extend(utc)
dayjs.extend(relativeTime)

const formatDate = (x: string | Date, dateFormat = 'MMM, DD, YYYY'): string => {
  return dayjs(x instanceof Date ? x : new Date(x)).format(dateFormat)
}

const formatDateFromNow = (x: string, dateFormat = 'MMM DD, YYYY'): string => {
  const date = new Date(x)
  if (dayjs().add(-1, 'week') > dayjs(date)) {
    return formatDate(x, dateFormat)
  }
  return dayjs(date).fromNow()
}

const formatDateDiff = (x: string): string => {
  const date = dayjs(x)
  const diffInMilliseconds = Date.now() - date.valueOf()
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)

  if (diffInMinutes < 15) {
    return `Edited a few minutes ago`
  } else if (diffInMinutes < 30) {
    return `Edited ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`
  } else if (diffInMinutes < 60) {
    return `Edited less than an hour ago`
  }

  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)
  const diffInMonths = Math.floor(diffInDays / 30)
  const diffInYears = Math.floor(diffInDays / 365)

  if (diffInDays < 30) {
    return `Edited ${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`
  } else if (diffInMonths < 12) {
    return `Edited more than 1 month ago`
  } else {
    return `Edited more than 1 year ago`
  }
}

const formatters = {
  formatDate: formatDate,
  formatDateFromNow: formatDateFromNow,
  formatDateDiff: formatDateDiff,
}

declare module 'vue/types/vue' {
  // Module augmentation for Vue
  interface Vue {
    $formatters: typeof formatters
  }
  interface VueConstructor {
    $formatters: typeof formatters
  }
}

const dayjsFormatters: Plugin = (context, inject) => {
  inject('dayjs', dayjs)
  inject('formatters', formatters)
}
export default dayjsFormatters
