import { IDocsPageContent, IDocsChapter } from '~/domain/interfaces/DocsTypes'
import { serializeQueryString } from '~/domain/helpers/helpers'

export default {
  async loadChapters({ state, commit }, { context }) {
    if (state.chapters.length > 0) {
      return
    }
    const { error, $axios, $config } = context
    const fullChapters = await $axios.$get(`${$config.nuxtBaseURL}/wp-json/docs/chapters`)
    const chapters = fullChapters?.map((chapter: IDocsChapter) => {
      return {
        id: chapter.id,
        slug: chapter.slug,
        title: chapter.title,
        pagesCount: chapter.count,
        pages: chapter.pages,
        icon: chapter.icon,
      }
    })

    if (!chapters?.length) {
      error({
        statusCode: 404,
        message: 'DOC_NOT_FOUND',
      })
    }

    commit('setChapters', chapters)
    commit('setChaptersLoaded', true)
    return chapters
  },

  async selectChapterBySlug(
    { state, commit },
    { context, slugChapter, wpBaseURL }
  ) {
    if (!slugChapter) {
      return
    }

    const urlChapter = `${wpBaseURL}/wp-json/wp/v2/doc-category?slug=${slugChapter}`
    const rawChapter = await fetch(urlChapter)
    const dataChapter = await rawChapter.json()

    if (!dataChapter || !dataChapter.length) {
      return
    }

    const chapter = dataChapter[0]

    if (!chapter) {
      return
    }

    const selectedChapter = {
      id: chapter.id,
      title: chapter.name,
      slug: chapter?.slug,
      pageCount: chapter.count,
    }

    return selectedChapter
  },

  async selectPageBySlug({ commit }, { context, slugPage }) {
    if (!slugPage) {
      return
    }
    const { $axios, $config, route } = context
    let queryParams = '',
      isPreview = false
    if (route?.query?.preview) {
      queryParams = '?' + serializeQueryString(route?.query)
      isPreview = true
    }
    const dataPage = await $axios.get(
      `${$config.nuxtBaseURL}/wp-json/docs/slug/${slugPage}${queryParams}`
    )
    if (!dataPage || dataPage.status !== 200 || dataPage.data?.length === 0) {
      return
    }

    const page = isPreview ? dataPage?.data : dataPage?.data?.[0]
    // TODO: return parent page from wordpress directly.
    const parentPageResponse = page.parent ? (await $axios.get(`${$config.nuxtBaseURL}/wp-json/docs/post/${page.parent}`)): {};
    const parentPage = parentPageResponse.data ? {
      id: parentPageResponse.data.id,
      slug: parentPageResponse.data.slug,
      title: parentPageResponse.data.title,
      chapter: parentPageResponse.data.category,
    } : {}

    const selectedPage = {
      id: page.id,
      title: page.title,
      slug: page.slug,
      body: page.content,
      content: page.content,
      category: page.category,
      chapter: page.category,
      link: page.link,
      parent: parentPage,
    } as IDocsPageContent
    if (page.nav) {
      if (page.nav.prev) {
        selectedPage.prev = {
          slug: page.nav.prev.slug,
          title: page.nav.prev.title,
          chapter: page.nav.prev.category,
        }
      }
      if (page.nav.next) {
        selectedPage.next = {
          slug: page.nav.next.slug,
          title: page.nav.next.title,
          chapter: page.nav.next.category,
        }
      }
    }

    commit('selectPage', selectedPage)

    commit('wp/updatePageTitle', page.title, {
      root: true,
    })
    if (page.yoast_meta_description) {
      commit(
        'wp/updatePageHead',
        {
          og_description: page.yoast_meta_description,
        },
        {
          root: true,
        }
      )
    }

    return selectedPage
  },

  // TODO: currently not in use. might be added for docs searchbar.
  async searchDoc({ state, commit }, { context, term }) {
    const { $axios, $config } = context
    return await $axios.$get(`${$config.nuxtBaseURL}/wp-json/docs/search/${term}`)
  },
}
