import { CookieOption } from '@axten/cookies-controller/dist/types/src/cookies-controller.vue';
import { machinationsCookiePreferences } from './../../domain/constants/CookiesConst';
import { CookiesControllerState } from '~/domain/interfaces/CookiesStateTypes';
import { Context } from '@nuxt/types';
export default {
  initCookies: (state: CookiesControllerState, params: {localStorage: Storage, context: Context}) => {
    if(params.localStorage) {
      const cookiesPref = params.localStorage.getItem('cookiesPref')
      if(cookiesPref) {
        const cookiesState: CookiesControllerState = JSON.parse(cookiesPref)
        state.isBannerVisible = cookiesState.isBannerVisible
        if(cookiesState.cookies)
          state.cookies = cookiesState.cookies
        else {
          state.cookies = machinationsCookiePreferences(params.context.$config.cookiesEnvConfig)
        }
      } else {
        //load default
        state.isBannerVisible = true
        state.cookies = machinationsCookiePreferences(params.context.$config.cookiesEnvConfig)
      }
    }
  },
  setCookies: (state: CookiesControllerState, 
    params: {localStorage: Storage, cookiesData: {[key: string]: CookieOption[]}}) => {
    state.cookies = params.cookiesData
    state.isBannerVisible = false
    if(params.localStorage) {
      params.localStorage.setItem('cookiesPref', JSON.stringify(state))
    }
    if(process.browser) {
      window.location.reload();
    }
  },
  setBannerVisibility: (state: CookiesControllerState,
    params: {visible: boolean, localStorage: Storage}) => {
      state.isBannerVisible = params.visible
      if(params.localStorage) {
        params.localStorage.setItem('cookiesPref', JSON.stringify(state))
      }
    }
}