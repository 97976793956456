import { UserType, UserBannerSpotlight } from '@interfaces/UserTypes'

export function getUserLocationAsString(user: UserType): string {
  if (user && user.location) {
    const arr = Object.keys(user.location).reduce(
      (acc: string[], key: string) => {
        const value: string = user.location ? user.location[key] : null

        if (value) {
          acc.push(value)
        }

        return acc
      },
      []
    )

    return arr.join(', ')
  }
  return ''
}

// eslint-disable-next-line
export function getUserJobAndLocation(job: string, location: any): string {
  if (job && location?.countryCode) {
    return job + ', ' + location.countryCode.toUpperCase()
  } else if (job) {
    return job
  } else if (location?.country) {
    return `${location?.country}`
  } else return ''
}

export function getUserFullName(user: UserType | UserBannerSpotlight | null | undefined): string {
  return user ? (user.name ? user.name : user.username) : ''
}

export function getUserJoinedAt(user: UserType): string | Date {
  const d = user.createdAt
  if (d !== undefined && d) {
    return d instanceof Date ? d : new Date(d)
    // return dayjs(d instanceof Date ? d : new Date(d)).format('MMMM YYYY')
  }
  return 'not specified'
}
