import { SegmentEventParams } from '~/domain/repositories/SegmentRepo';

import { CommunityRepo } from '@repositories/CommunityRepo'
import { Context } from '@nuxt/types'

export default {
  // eslint-disable-next-line
  trackSegmentEvent({ state }: any, data: SegmentEventParams) {
    if (state.trackSegmentEvent) {
      state.trackSegmentEvent(
        null,
        data.name,
        data.category,
        data.action,
        data.label,
        data.value || '',
        data.misc
      )
    }
  },

  async fetchActivityFeed({ commit, state }, context: Context) {
    const repoCommunity = new CommunityRepo(context)
    const activityFeedContent = await repoCommunity.fetchActivityFeed({
      count: state.activityFeed.recordsPerPage,
      page: state.activityFeed.lastPage + 1,
      username: '',
    })

    commit('activityFeedAddList', activityFeedContent.list)
    commit('activityFeedIncrementPage', activityFeedContent)
  },
}
