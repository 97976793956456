import DefaultAvatarIcon from '@components/icons/DefaultAvatarIcon.vue'
import MachinationsLogoCustomColor from '@/components/icons/MachinationsLogoCustomColor.vue'
import LogoMachination from '@/components/icons/LogoMachination.vue'
import HeartIcon from '@/components/icons/HeartIcon.vue'
import ViewIcon from '@/components/icons/ViewIcon.vue'
import NothingPublishedIcon from '@/components/icons/NothingPublishedIcon.vue'
import FacebookIcon from '@/components/icons/social/FacebookIcon.vue'
import LinkedinIcon from '@/components/icons/social/LinkedinIcon.vue'
import TwitterIcon from '@/components/icons/social/TwitterIcon.vue'
import DiscordIcon from '@/components/icons/social/DiscordIcon.vue'
import MapMarkerIcon from '@/components/icons/MapMarkerIcon.vue'
import DiagramThumbnailPlaceholder from '@/components/icons/DiagramThumbnailPlaceholder.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue'
import ArrowRightIconThin from '@/components/icons/ArrowRightIconThin.vue'
import ArrowRightSharpThinIcon from '@/components/icons/ArrowRightSharpThinIcon.vue'
import ArrowTargetRightIcon from "@/components/icons/ArrowTargetRightIcon.vue";
import ArrowThin from '@/components/icons/ArrowThin.vue'
import SelectArrowDownIcon from '@/components/icons/SelectArrowDownIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import StarIcon from '@/components/icons/StarIcon.vue'
import PlueIcon from '@/components/icons/PlusIcon.vue'
import DotIcon from '@/components/icons/DotIcon.vue'
import PlansPlusIcon from '@/components/icons/PlansPlusIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import TrendingIcon from '@/components/icons/TrendingIcon.vue'
import FollowedIcon from '@/components/icons/FollowedIcon.vue'
import FollowIcon from '@/components/icons/FollowIcon.vue'
import UnfollowIcon from '@/components/icons/UnfollowIcon.vue'
import ForkIcon from '@/components/icons/ForkIcon.vue'
import ScrollTopIcon from '@/components/icons/ScrollTopIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import LinkedinIconCustom from '@/components/icons/LinkedinIcon.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import ArrowLeftIconThin from '@/components/icons/ArrowLeftIconThin.vue'
import ArrowLeftSharpThinIcon from '@/components/icons/ArrowLeftSharpThinIcon.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import HireIcon from '@/components/icons/HireIcon.vue'
import NotificationIcon from '@/components/icons/NotificationIcon.vue'
import InstagramSocialIcon from '@/components/icons/social/game-socials/InstagramSocialIcon.vue'
import FacebookSocialIcon from '@/components/icons/social/game-socials/FacebookSocialIcon.vue'
import LinkedinSocialIcon from '@/components/icons/social/game-socials/LinkedinSocialIcon.vue'
import DiscordSocialIcon from '@/components/icons/social/game-socials/DiscordSocialIcon.vue'
import TwitterSocialIcon from '@/components/icons/social/game-socials/TwitterSocialIcon.vue'
import TwitterFilledIcon from '@/components/icons/social/game-socials/TwitterFilledIcon.vue'
import YoutubeSocialIcon from '@/components/icons/social/game-socials/YoutubeSocialIcon.vue'
import RedditSocialIcon from '@/components/icons/social/game-socials/RedditSocialIcon.vue';
import EthereumIcon from '@/components/icons/EthereumIcon.vue'
import CheckRoundedIcon from '@/components/icons/CheckRoundedIcon.vue'
import TeamIcon2 from '@/components/icons/TeamIcon.vue'
import UndockIcon from '@/components/icons/UndockIcon.vue'
import BackToTopIcon from '@/components/icons/BackToTopIcon.vue'
import ExternalLinkIcon from '@/components/icons/ExternalLinkIcon.vue'

import PlayIcon from '@/components/icons/PlayIcon.vue'
import MenuIcon from '@/components/icons/MenuIcon.vue'

import DoubleCheckIcon from '@/components/icons/DoubleCheckIcon.vue'
import BellIcon from '@/components/icons/BellIcon.vue'
import BagIcon from '@/components/icons/BagIcon.vue'

import Web3CheckIcon from '@/components/icons/Web3CheckIcon.vue'
import GlobeIcon from '@/components/icons/GlobeIcon.vue'
import BoltIcon from '@/components/icons/BoltIcon.vue'
import TrialBadgeIcon from '@/components/icons/TrialBadgeIcon.vue'

import NewDesignIcon from '@/components/icons/Homepage/NewDesignIcon.vue'
import IGoogle from '@/components/icons/social/GoogleIcon.vue'
import StarsIcon from '@/components/icons/Company/StarsIcon.vue'
import CodeIcon from '@/components/icons/Company/CodeIcon.vue'
import AddUserIcon from '@/components/icons/Company/AddUserIcon.vue'
import CommunityMenuIcon from '@/components/icons/CommunityMenuIcon.vue'
import YoutubeCommIcon from '@/components/icons/social/communiyMenu/YoutubeCommIcon.vue'
import DiscordCommIcon from '@/components/icons/social/communiyMenu/DiscordCommIcon.vue'
import BurgerMenuIcon from '@/components/icons/BurgerMenuIcon.vue'
import MailIcon from '@/components/icons/MailIcon.vue'
import WorldIcon from '@/components/icons/WorldIcon.vue'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import PlayButton from '@/components/icons/PlayButton.vue'
import TestimonialLeftArrowIcon from "@/components/icons/TestimonialLeftArrowIcon.vue"
import TestimonialRightArrowIcon from "@/components/icons/TestimonialRightArrowIcon.vue"
import MinusIcon from "@/components/icons/MinusIcon.vue"
import SecurityIcon from '@/components/icons/SecurityIcon.vue'

import AllIcon from '@/components/icons/Search/AllIcon.vue'
import DesignersIcon from '@/components/icons/Search/DesignersIcon.vue'
import DiagramsIcon from '@/components/icons/Search/DiagramsIcon.vue'
import GlossaryIcon from '@/components/icons/Search/GlossaryIcon.vue'
import ArticlesIcon from '@/components/icons/Search/ArticlesIcon.vue'
import AcademicIcon from '@/components/icons/Search/AcademicIcon.vue'
import DocumentationIcon from '@/components/icons/Search/DocumentationIcon.vue'
import NoResultsAllIcon from '@/components/icons/Search/NoResultsAllIcon.vue'
import NoResultsDocumentationIcon from '@/components/icons/Search/NoResultsDocumentationIcon.vue'
import NoResultsDiagramsIcon from '@/components/icons/Search/NoResultsDiagramsIcon.vue'
import NoResultsDesignersIcon from '@/components/icons/Search/NoResultsDesignersIcon.vue'
import LearningIcon from '@/components/icons/LearningIcon.vue'
import CreativeCommonsIcon1 from '@/components/icons/CreativeCommonsIcon1.vue'
import CreativeCommonsIcon2 from '@/components/icons/CreativeCommonsIcon2.vue'
import MessageIcon from '@/components/icons/MessageIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import YesIcon from '@/components/icons/YesIcon.vue'
import NoIcon from '@/components/icons/NoIcon.vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import PlusIconSlim from '@/components/icons/PlusIconSlim.vue'
import VisibilityOffIcon from '@/components/icons/VisibilityOffIcon.vue'
import ArrowUpLightIcon from '@/components/icons/ArrowUpLightIcon.vue'
import CommunityNoResultsIcon from '@/components/icons/CommunityNoResultsIcon.vue'

import Vuetify from 'vuetify/lib'
import Vue from 'vue'
import minifyTheme from 'minify-css-string'
const { LRUCache } = require('lru-cache')

import {
  VApp,
  VBtn,
  VCard,
  VChip,
  VContainer,
  VDivider,
  VIcon,
  VTextField,
  VProgressCircular,
  VBadge,
  VList,
  VListItem,
  VListItemTitle,
  VToolbar,
  VFadeTransition,
  VTooltip,
  VAvatar,
  VDialog,
  VMenu,
  VSwitch,
  VSheet,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VDataTable,
  VCol,
  VRow,
  VCheckbox,
  VForm,
  VImg,
  VTimeline,
  VTimelineItem,
  VTab,
  VTabs,
  VTabsSlider,
  VPagination,
  VSelect,
  VCarousel,
  VListGroup,
  VCarouselItem,
  VChipGroup,
  VOverlay,
  VSkeletonLoader,
  VExpandXTransition,
  VNavigationDrawer,
  VResponsive,
  VSubheader,
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VApp,
    VBtn,
    VCard,
    VChip,
    VContainer,
    VDivider,
    VDialog,
    VIcon,
    VTextField,
    VProgressCircular,
    VBadge,
    VList,
    VListItem,
    VListItemTitle,
    VToolbar,
    VFadeTransition,
    VTooltip,
    VAvatar,
    VMenu,
    VSwitch,
    VSheet,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VDataTable,
    VCol,
    VRow,
    VCheckbox,
    VForm,
    VImg,
    VTimeline,
    VTimelineItem,
    VTab,
    VTabs,
    VTabsSlider,
    VPagination,
    VSelect,
    VCarousel,
    VCarouselItem,
    VChipGroup,
    VOverlay,
    VSkeletonLoader,
    VExpandXTransition,
    VNavigationDrawer,
    VResponsive,
    VSubheader,
    VListGroup,
  },
})

const themeCache = new LRUCache({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: false,
      minifyTheme,
      themeCache,
    },
    dark: false,
    themes: {
      light: {
        /* Standard properties */
        primary: '#5A55F4',
        secondary: '#5A55F4',
        accent: '#5A55F4',
        error: '#F36161',
        info: '#2196F3',
        success: '#5A55F4',
        warning: '#FFC107',
        /* Custom properties */
        newBackground: '#F7F6FB',
        background: '#FFFFFF',
        menuShadow: '#484C5A',
        darkPrimary: '#1D1D1D',
        darkSecondary: '#070707',
        white: '#ffffff',
        gray: '#1A1A1A',
        grey: '#1D1D1D',
        black: '#000000',
        green: '#66BB6A',
        machinationsGray: '#97999B',
        machinationsDarkGray: '#53565A',
        lightBlue: '#F7F9FE',
        stroke: '#D6DAE7',
        lightBlueBg: '#ECEFFC',
        basicText: '#53565A',
        lightText: '#97999B',
      },
      dark: {
        /* Standard properties */
        primary: '#5A55F4',
        secondary: '#5A55F4',
        accent: '#5A55F4',
        error: '#F36161',
        info: '#2196F3',
        success: '#5A55F4',
        warning: '#FFC107',
        /* Custom properties */
        newBackground: '#F7F6FB',
        background: '#000000',
        menuShadow: '#999999',
        darkPrimary: '#FFFFFF',
        darkSecondary: '#FAFAFA',
        white: '#000000',
        gray: '#ffffff',
        grey: '#1D1D1D',
        black: '#FFFFFF',
        green: '#66BB6A',
        machinationsGray: '#97999B',
        machinationsDarkGray: '#53565A',
        lightBlue: '#F7F9FE',
        stroke: '#53565A',
        lightBlueBg: '#1D1D1D',
        basicText: '#FFFFFF',
        lightText: '#97999B',
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      machinationsLogoCustomColor: {
        component: MachinationsLogoCustomColor,
      },
      heartIcon: {
        component: HeartIcon,
      },
      heartStrokeIcon: {
        component: HeartIcon,
        props: {
          filled: false,
        },
      },
      viewIcon: {
        component: ViewIcon,
      },
      eyeIcon: {
        component: ViewIcon,
      },
      searchIcon: {
        component: SearchIcon,
      },
      // Used in @axten/docs:
      search: {
        component: SearchIcon,
      },
      nothingPublishedIcon: {
        component: NothingPublishedIcon,
      },
      facebookIcon: {
        component: FacebookIcon,
      },
      twitterIcon: {
        component: TwitterIcon,
      },
      linkedinIcon: {
        component: LinkedinIcon,
      },
      discordIcon: {
        component: DiscordIcon,
      },
      undockIcon: {
        component: UndockIcon,
      },
      mapMarkerIcon: {
        component: MapMarkerIcon,
      },
      diagramThumbnailPlaceholder: {
        component: DiagramThumbnailPlaceholder,
      },
      defaultAvatarIcon: {
        component: DefaultAvatarIcon,
      },
      closeIcon: {
        component: CloseIcon,
      },
      closeCircleIcon: {
        component: CloseCircleIcon,
      },
      arrowRightIcon: {
        component: ArrowRightIcon,
      },
      arrowRightIconThin: {
        component: ArrowRightIconThin,
      },
      arrowRightSharpThinIcon: {
        component: ArrowRightSharpThinIcon,
      },
      arrowTargetRightIcon: {
        component: ArrowTargetRightIcon,
      },
      arrowThin: {
        component: ArrowThin,
      },
      arrowDownIcon: {
        component: ArrowDownIcon,
      },
      // used in @axten/docs:
      arrowLeftIcon: {
        component: ArrowLeftIcon,
      },
      arrowLeftIconThin: {
        component: ArrowLeftIconThin,
      },
      arrowLeftSharpThinIcon: {
        component: ArrowLeftSharpThinIcon,
      },
      selectArrowDownIcon: {
        component: SelectArrowDownIcon,
      },
      notificationIcon: {
        component: NotificationIcon,
      },
      starIcon: {
        component: StarIcon,
      },
      plusIcon: {
        component: PlueIcon,
      },
      plansPlusIcon: {
        component: PlansPlusIcon,
      },
      checkIcon: {
        component: CheckIcon,
      },
      trendingIcon: {
        component: TrendingIcon,
      },
      followIcon: {
        component: FollowIcon,
      },
      followedIcon: {
        component: FollowedIcon,
      },
      unfollowIcon: {
        component: UnfollowIcon,
      },
      forkIcon: {
        component: ForkIcon,
      },
      scrollTopIcon: {
        component: ScrollTopIcon,
      },
      machinationsLogo: {
        component: MachinationsLogoCustomColor,
      },
      web3GameLogo: {
        // TODO: rename! this is the logo used for web3 games, not the logo of machinations
        component: LogoMachination,
      },
      googleLogo: {
        component: IGoogle,
      },
      instagramSocialIcon: {
        component: InstagramSocialIcon,
      },
      facebookSocialIcon: {
        component: FacebookSocialIcon,
      },
      linkedinSocialIcon: {
        component: LinkedinSocialIcon,
      },
      discordSocialIcon: {
        component: DiscordSocialIcon,
      },
      twitterSocialIcon: {
        component: TwitterSocialIcon,
      },
      twitterFilledIcon: {
        component: TwitterFilledIcon,
      },
      youtubeSocialIcon: {
        component: YoutubeSocialIcon,
      },
      ethereumIcon: {
        component: EthereumIcon,
      },
      checkRoundedIcon: {
        component: CheckRoundedIcon,
      },
      infoIcon: {
        component: InfoIcon,
      },
      linkedinIconCustom: {
        component: LinkedinIconCustom,
      },
      teamIcon: {
        component: TeamIcon2,
      },
      playIcon: {
        component: PlayIcon,
      },
      hireIcon: {
        component: HireIcon,
      },
      hireIconGreen: {
        component: HireIcon,
        props: {
          color: 'var(--v-green-base)',
        },
      },
      menuIcon: {
        component: MenuIcon,
      },
      bellIcon: {
        component: BellIcon,
      },
      bagIcon: {
        component: BagIcon,
      },
      doubleCheckIcon: {
        component: DoubleCheckIcon,
      },
      web3CheckIcon: {
        component: Web3CheckIcon,
      },
      backToTopIcon: {
        component: BackToTopIcon,
      },
      dotIcon: {
        component: DotIcon,
      },
      externalLinkIcon: {
        component: ExternalLinkIcon,
      },
      boltIcon: {
        component: BoltIcon,
      },
      globeIcon: {
        component: GlobeIcon,
      },
      trialBadge: {
        component: TrialBadgeIcon,
      },
      newDesignIcon: {
        component: NewDesignIcon,
      },
      starsIcon: {
        component: StarsIcon,
      },
      codeIcon: {
        component: CodeIcon,
      },
      addUserIcon: {
        component: AddUserIcon,
      },
      communityMenuIcon: {
        component: CommunityMenuIcon,
      },
      commYoutubeIcon: {
        component: YoutubeCommIcon,
      },
      commDiscordIcon: {
        component: DiscordCommIcon,
      },
      worldIcon: {
        component: WorldIcon,
      },
      burgerMenuIcon: {
        component: BurgerMenuIcon,
      },
      mailIcon: {
        component: MailIcon,
      },
      downloadIcon: {
        component: DownloadIcon,
      },
      securityIcon: {
        component: SecurityIcon
      },
      playButton: {
        component: PlayButton
      },
      testimonialLeftArrowIcon: {
        component: TestimonialLeftArrowIcon
      },
      testimonialRightArrowIcon: {
        component: TestimonialRightArrowIcon
      },
      minusIcon: {
        component: MinusIcon
      },
      allIcon: {
        component: AllIcon,
      },
      designersIcon: {
        component: DesignersIcon,
      },
      diagramsIcon: {
        component: DiagramsIcon,
      },
      glossaryIcon: {
        component: GlossaryIcon,
      },
      articlesIcon: {
        component: ArticlesIcon,
      },
      academicIcon: {
        component: AcademicIcon,
      },
      documentationIcon: {
        component: DocumentationIcon,
      },
      noResultsAllIcon: {
        component: NoResultsAllIcon,
      },
      noResultsDocumentationIcon: {
        component: NoResultsDocumentationIcon,
      },
      noResultsDiagramsIcon: {
        component: NoResultsDiagramsIcon,
      },
      noResultsDesignersIcon: {
        component: NoResultsDesignersIcon,
      },
      learningIcon: {
        component: LearningIcon
      },
      creativeCommonsIcon1: {
        component: CreativeCommonsIcon1
      },
      creativeCommonsIcon2: {
        component: CreativeCommonsIcon2
      },
      messageIcon: {
        component: MessageIcon
      },
      logoutIcon: {
        component: LogoutIcon
      },
      yesIcon: {
        component: YesIcon
      },
      noIcon: {
        component: NoIcon
      },
      halfIcon: {
        component: HalfIcon
      },
      plusIconSlim: {
        component: PlusIconSlim
      },
      visibilityOffIcon: {
        component: VisibilityOffIcon
      },
      redditSocialIcon: {
        component: RedditSocialIcon
      },
      arrowUpLightIcon: {
        component: ArrowUpLightIcon
      },
      communityNoResultsIcon: {
        component: CommunityNoResultsIcon
      },
    },
  },
})
