import { Context } from '@nuxt/types'
import { isNil } from 'lodash-es'

export class BaseRepo {
  protected nuxtContext: Context
  protected apiBaseURL: string
  protected wpBaseURL: string
  protected nuxtBaseURL: string

  constructor(context: Context) {
    if (isNil(context)) {
      return
    }

    this.nuxtContext = context || {}

    const { $config } = context
    const { apiBaseURL, wpBaseURL, nuxtBaseURL } = $config

    this.apiBaseURL = apiBaseURL
    this.wpBaseURL = wpBaseURL
    this.nuxtBaseURL = nuxtBaseURL
  }

  prepareHeaders($axios) {
    $axios.setHeader('Content-Type', 'application/json')

    if (process.server) {
      $axios.setHeader('Referer', this.nuxtBaseURL)
    }
  }

  prepareQueryStringFrom(params) {
    const queryString = Object.keys(params).reduce((acc, key) => {
      if (acc !== '') {
        acc += '&'
      }
      acc += key + '='

      if (Array.isArray(params[key])) {
        acc += encodeURIComponent(params[key].join(','))
      } else {
        acc += encodeURIComponent(params[key])
      }

      return acc
    }, '')

    return queryString
  }
}
