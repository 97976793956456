import { render, staticRenderFns } from "./error.vue?vue&type=template&id=569b49e5"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHeader: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/homepage/HomepageHeader.vue').default,PageNotFound: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/page/PageNotFound.vue').default,BContainer: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/BContainer.vue').default,PageFooter: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/page/PageFooter.vue').default})
