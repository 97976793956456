export const SEARCH_CATEGORIES = {
    'all': {
        name: 'All',
        icon: 'allIcon',
        noResultsIcon: 'noResultsAllIcon',
        displayLimit: 0,
    },
    'documentation': {
        name: 'Documentation',
        icon: 'documentationIcon',
        noResultsIcon: 'noResultsDocumentationIcon',
        displayLimit: 4,
    },
    'diagrams': {
        name: 'Diagrams',
        icon: 'diagramsIcon',
        noResultsIcon: 'noResultsDiagramsIcon',
        displayLimit: 4,
    },
    'designers': {
        name: 'Designers',
        icon: 'designersIcon',
        noResultsIcon: 'noResultsDesignersIcon',
        displayLimit: 5,
    },
    // 'glossary': {
    //     name: 'Glossary',
    //     icon: 'glossaryIcon',
    //     noResultsIcon: '',
    //     displayLimit: 4,
    // },
    // 'articles': {
    //     name: 'Articles',
    //     icon: 'articlesIcon',
    //     noResultsIcon: '',
    //     displayLimit: 4,
    // },
    // 'academic-research': {
    //     name: 'Academic research',
    //     icon: 'academicIcon',
    //     noResultsIcon: '',
    //     displayLimit: 4,
    // },
};