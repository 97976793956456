import { IDocsState } from './../../domain/docs/types';

export default {
  getChapters: (state: IDocsState) => {
    return state.chapters;
  },

  getChaptersPages: (state: IDocsState) => (slugChapter) => {
    const chapter = state.chapters.find(objChapter => objChapter.slug === slugChapter);

    if (!chapter) {
      return [];
    }

    return chapter.pages;
  },

  pageTitle: (state) => (page) => {
    return page.title;
  },

  selectedChapter: (state: IDocsState) => state.selectedChapter,

  selectedPage: (state: IDocsState) => state.selectedPage
}