export default async function (to, from, savedPosition) {
  const findEl = async (hash, x = 0) => {
    return (
      document.querySelector(hash) ||
      new Promise((resolve) => {
        if (x > 50) {
          return resolve(document.querySelector('#app'))
        }
        setTimeout(() => {
          resolve(findEl(hash, ++x || 1))
        }, 100)
      })
    )
  }

  if (to.hash) {
    let el = await findEl(to.hash)
    if ('scrollBehavior' in document.documentElement.style) {
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(to.hash).offsetTop - 120,
          behavior: 'smooth',
        })
      }, 0)
    } else {
      if (el && el.offsetTop) {
        window.scrollTo(0, el.offsetTop)
      }
    }
    // Return an empty object to satisfy the return type
    return {}
  }

  return { x: 0, y: 0, left: 0, top: 0 }
}
