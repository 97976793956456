
import Vue from 'vue'
import type { PropType } from 'vue'
import { UserType, UserBannerSpotlight } from '@interfaces/index'

export default Vue.extend({
  name: 'ProfileAvatar',
  props: {
    user: {
      type: Object as PropType<UserType | UserBannerSpotlight>,
      required: true,
    },
    size: {
      type: Number,
      default: 124,
    },
    fullDetails: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      avatarUrl: `/wp-content/uploads/community/img/avatarPlaceholder.png`,
    }
  },
})
