import { PricingStoreState } from "@interfaces/PricingTypes";

export default {

  updatePlanFeatures(state: PricingStoreState, data) {
    state.plansFeatures = data;
  },

  updateProdConfig(state: PricingStoreState, data) {
    state.prodConfig = data;
  },

};
