import { NuxtAxiosInstance } from '@nuxtjs/axios'

export default function ({
  $axios,
  redirect,
}: {
  $axios: NuxtAxiosInstance
  redirect: any
}) {
  $axios.onRequest((config) => {
    if (
      config &&
      config.headers &&
      (config.url?.includes('photo') || config.url?.includes('shortcode'))
    ) {
      delete config.headers?.['Authorization']
      if (config.headers.common) {
        delete config.headers?.common?.['Authorization']
      }
    }
    return config
  })
  $axios.onError((error) => {
    if (error.response) {
      // server responded with a status code !== 2xx
      if (error.response?.status >= 500 && error.response?.status < 600) {
        console.log(
          '-- axios response server error:',
          error.response?.status,
          error.response?.data
        )
      }
    } else if (error.request) {
      // request made but no response received
    } else {
      if (!error.message?.includes("(reading 'Authorization')")) {
        console.log('-- axios error:', error.message)
      }
    }
  })
}
