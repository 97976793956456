import { BaseRepo } from "./BaseRepo";

export class MainAppRepo extends BaseRepo {

  async fetchData(dataName: string) {
    const { $axios } = this.nuxtContext;
    let communityRoute = `${this.apiBaseURL}/community/${dataName}`;
    let decoded = null, result;

    this.prepareHeaders($axios);

    result = await $axios.$get(communityRoute);

    if (result.success === true) {
      decoded = JSON.parse(decodeURIComponent(result.content))
    } else {
      // console.error(result.content)
      console.error('axios ERROR')
    }

    return decoded;
  }

  async fetchSendContactEmail(params: {recaptchaToken: string, firstName: string, lastName: string, email: string, subject:string, message: string, job: string}) {
    const { $axios } = this.nuxtContext;
    const contactFormRoute = `${this.apiBaseURL}/contact/form`;
    this.prepareHeaders($axios);
    return await $axios.$post(contactFormRoute, params);
  }

  async fetchSendLiveopsWhitepaperEmail(params: {recaptchaToken: string, email: string, firstName: string, lastName: string, company: string}) {
    const { $axios } = this.nuxtContext;
    const liveopsWhitePaperFormRoute = `${this.apiBaseURL}/liveops-whitepaper/form`;
    this.prepareHeaders($axios);
    return await $axios.$post(liveopsWhitePaperFormRoute, params);
  }

  async fetchSearchResults(params: {search: string, filter: string, page: number}): Promise<any> {
    const { $axios } = this.nuxtContext
    let routeSearchResults = `${this.apiBaseURL}/community/search/`
    const queryString = this.prepareQueryStringFrom(params)

    if (queryString !== '') {
      routeSearchResults += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeSearchResults)

    return result.content
  }

  async fetchSearchSuggestions(params: {term: string, count: number}): Promise<any> {
    const { $axios } = this.nuxtContext
    let routeSearchResults = `${this.apiBaseURL}/community/suggestions/`
    const queryString = this.prepareQueryStringFrom(params)

    if (queryString !== '') {
      routeSearchResults += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeSearchResults)

    return result.content
  }

}
